import React, { useState, useEffect } from "react";
import styles from "./change-password.module.scss";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getLoginData, passwordUpdated } from "../../store/reducers/loginData";
import constants from "../../utils/constants";
import InputErrorMessage from "../../app/components/inputErrorMessage";
import { useNavigate } from "react-router-dom";
import toastMessage from "../../utils/toastMessage";
import Spinner from "react-bootstrap/Spinner";
import {fetchPatchRequest} from "../../utils/network";
import endpoints from "../../utils/endpoints";

const ChangePassword = () => {
    const [updatingPassword, setUpdatingPassword] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialValues = {
        password: "",
        confirmPassword: ""
    };

    const validationSchema = yup.object().shape({
        password: yup.string().required("Please Enter your password").trim(),
        confirmPassword: yup.string().required("Please Enter your password").trim(),
    });

    const confirmAccount = async (values) => {
        const { password, confirmPassword } = values;
        try {
            const response = await fetchPatchRequest(
                endpoints.auth.changePassword, {
                newPassword: password, confirmPassword
            })
            if(response.status < 400) {
                toastMessage(constants.TOAST_TYPE.SUCCESS, response?.data?.message);
                dispatch(passwordUpdated())
                navigate("/")
            } else {
                toastMessage(constants.TOAST_TYPE.ERROR, response?.data?.message);
            }
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        }
    };

    // useEffect(() => {
    //     if(!loginData?.user?.userSettings?.needToChangePassword) {
    //         navigate('/')
    //     }
    // }, [])

    return (
        <div className={styles.loginBody}>
            <div className={styles.container}>
                <div className={styles.loginContent}>
                    <div className={styles.loginContentLeft}>
                        <img src={"../../images/logo.png"} alt="Young Automotive Group" style={{ height: '200px', width: 'auto' }} />
                    </div>
                    <div className={styles.loginContentRight}>
                        <div className={styles.loginForm}>
                            <h2>Create A New Password</h2>
                            <p>Your new password must be different from your previous password.</p>
                            <br />
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={(values) => {
                                    if(values.password !== values.confirmPassword) {
                                        return toastMessage(constants.TOAST_TYPE.ERROR, "Password & Confirm Password must be same");
                                    }
                                    confirmAccount(values);
                                }}
                            >
                                {({ values, setFieldValue, handleSubmit, handleBlur, handleChange }) => {
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <div className={styles.loginFormGroup}>
                                                <label>New Password</label>
                                                <input
                                                    type="password"
                                                    placeholder="New Password"
                                                    name="password"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.password}
                                                    pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$"
                                                    title="Password shoud consist of atleast upper & lower case characters, a number and a symbol"
                                                />
                                                <InputErrorMessage name="password" />
                                            </div>
                                            <br />
                                            <div className={styles.loginFormGroup}>
                                                <label>Confirm New Password</label>
                                                <input
                                                    type="password"
                                                    placeholder="Confirm New Password"
                                                    name="confirmPassword"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.confirmPassword}
                                                    pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$"
                                                    title="Password shoud consist of atleast upper & lower case characters, a number and a symbol"
                                                />
                                                <InputErrorMessage name="confirmPassword" />
                                            </div>
                                            <br />
                                            <div className={styles.butSec} style={{
                                                display: 'flex', justifyContent: 'flex-end'
                                            }}>
                                                <button className={styles.signBtn} type="submit">
                                                    {updatingPassword &&
                                                        <Spinner animation="border" size="sm" style={{ marginRight: '10px' }} />}
                                                    Save
                                                </button>
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ChangePassword;
