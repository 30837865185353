import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import styles from "../surveys.module.scss";
import Form from "react-bootstrap/Form";
import addQuestion_styles from './addQuestionPopup.module.scss';
import Spinner from "react-bootstrap/Spinner";
import {fetchPostRequest, fetchPatchRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";

export const QuestionEditForm = ({
    questionData, heading, isAdd, setQuestionData
}) => {
    const [question, setQuestion] = useState(questionData?.question || "");
    const [type, setType] = useState(questionData?.questionType
        ? (questionData?.questionType === 'single-choice' ? "multiple-choice" : questionData?.questionType)
        : "multiple-choice"); // "Multiple Choice", "Short Answer" or "Slider"
    const [mcqType, setMcqType] = useState(
        questionData?.questionType ? (questionData?.questionType === "single-choice" ? "single" : "multiple")
                : "single"
    ); // "single" or "multiple"
    const [options, setOptions] = useState(questionData?.options || (
        questionData?.questionType === "slider" ? ["", ""] : [""]
    ));

    const updateOption = (optionIndex, newValue) => {
        setOptions(x => {
            const y = [...x];
            y[optionIndex] = newValue;
            return y;
        })
    }

    const updateProp = (prop, value) => {
        setQuestionData(x => ({
            ...x,
            [prop]: value
        }))
    }

    useEffect(() => {
        if(type === "multiple-choice") {
            updateProp("questionType", mcqType === "single" ? "single-choice" : "multiple-choice");
        } else {
            updateProp("questionType", type);
        }

    }, [type, mcqType]);

    useEffect(() => {
        updateProp("options", options);
    }, [options]);

    useEffect(() => {
        updateProp("question", question);
    }, [question]);

    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label>{heading}</Form.Label>
                <Form.Control type="text" placeholder="Question" value={question} required
                      onChange={e => setQuestion(e.target.value)}
                      maxLength={250}
                />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>Answer Type</Form.Label>
                <Form.Select value={type} onChange={e => {
                    const newType = e.target.value;
                    if(newType === "slider")
                        setOptions(["", ""]);
                    setType(newType);
                }}>
                    <option value="multiple-choice">Multiple Choice</option>
                    <option value="short-answer">Short Answer</option>
                    <option value="slider">Slider</option>
                </Form.Select>
            </Form.Group>
            {(type === "multiple-choice") &&
                <>
                    <div style={{display: 'flex', gap: '30px', marginBottom: '8px'}}>
                        <div style={{display: 'flex', gap: '8px', alignItems: 'center'}} className={styles.clickable}
                             onClick={() => setMcqType("single")}>
                            <input type="radio" style={{width: '18px', height: '18px'}}
                                   checked={mcqType === "single"}
                            />
                            <div>Select one</div>
                        </div>
                        <div style={{display: 'flex', gap: '8px', alignItems: 'center'}} className={styles.clickable}
                             onClick={() => setMcqType("multiple")}>
                            <input type="radio" style={{width: '18px', height: '18px'}}
                                checked={mcqType === "multiple"}
                            />
                            <div>Select Multiple</div>
                        </div>
                    </div>
                    {options.map((option, index) =>
                        <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder={`Option ${index+1}`} value={option}
                                  onChange={e => updateOption(index, e.target.value)} required maxLength={50}/>
                        </Form.Group>)}
                    <div style={{display: 'flex', gap: '8px', alignItems: 'center'}} className={styles.clickable}
                        onClick={() => setOptions(x => [...x, ""])}>
                        <img src="/images/plus_add.svg" alt="add icon" />
                        <div style={{color: '#B4B4B4'}}>Add Another Option</div>
                    </div>
                </>}
            {type === "slider" &&
                <>
                    <Form.Group className="mb-3 mt-3">
                        <Form.Control type="text" placeholder="Left Side Text" value={options[0]}
                              onChange={e =>
                                  setOptions(x => [e.target.value, x?.[1]||""])} required maxLength={15}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control type="text" placeholder="Right Side Text" value={options[1]}
                              onChange={e =>
                                    setOptions(x => [x?.[0]||"", e.target.value])} required maxLength={15}/>
                    </Form.Group>
                </>}
        </>
    )
}


const AddQuestionPopup = ({
    questionData, onClose, heading, onSave, surveyId, onDelete
}) => {
    const [questionItem, setQuestionItem] = useState(questionData || {
        options: [], question: '', questionType: 'single-choice'
    });
    const [savingData, setSavingData] = useState(false);

    const addQuestion = async () => {
        setSavingData(true);
        const payload = {
            surveyId, ...questionItem
        }
        try {
            const resp = await fetchPostRequest(endpoints.surveys.questions.create, payload);
            if(resp.status < 400) {
                toastMessage(constants.TOAST_TYPE.SUCCESS, resp.data.message);
                onSave?.();
            }
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setSavingData(false);
        }
    }

    const updateQuestion = async () => {
        setSavingData(true);
        const payload = {
            surveyId, ...questionItem
        }
        delete payload.surveyQuestionId;
        try {
            const resp = await fetchPatchRequest(endpoints.surveys.questions.update(questionData.surveyQuestionId), payload);
            if(resp.status < 400) {
                toastMessage(constants.TOAST_TYPE.SUCCESS, resp.data.message);
                onSave?.();
            }
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setSavingData(false);
        }
    }


    return (
        <>
            <Modal show={true} scrollable size="lg">
                <Modal.Header style={{
                    borderBottom: 'none', display: 'flex', alignItems: 'center',  paddingTop: '40px',
                    paddingBottom: '20px', paddingLeft: '40px', paddingRight: '40px'
                }}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h3>
                            {questionData
                                ? "Edit Question"
                                : "Add a Question"}
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body style={{minHeight: '250px', maxHeight: '90vh'}}>
                    <Form className={addQuestion_styles.addQuestionForm} id="add-question-form"
                          style={{paddingLeft: '25px', paddingRight: '25px'}}
                          onSubmit={e => {
                              e.preventDefault(); e.stopPropagation();
                              if(questionData) {
                                  updateQuestion();
                              } else {
                                  addQuestion();
                              }
                          }}>
                        <QuestionEditForm questionData={questionData} heading={heading}
                              setQuestionData={setQuestionItem} isAdd={!questionData}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{display: 'flex', justifyContent: 'flex-end', borderTop: 'none',marginRight:"17px"}}>
                    {questionData &&
                        <button style={{
                            background: '#D30B0B', borderRadius: '100px', border: 'none',
                            width: '40px', height: '40px'
                        }}
                                onClick={e => onDelete?.()}>
                            <img src="/images/delete-icon.svg" style={{marginTop: '-3px'}} alt="delete icon"/>
                        </button>}
                    <button className={styles.secondaryBtn} style={{
                        padding: '5px 30px', width: '180px'
                    }} onClick={onClose}>
                        Cancel
                    </button>
                    <button className={styles.primaryBtn} style={{
                        padding: '5px 0px', width: '180px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }} form="add-question-form">
                        {savingData &&
                            <Spinner animation="border" style={{marginRight: '20px'}}/>}
                        Done
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default AddQuestionPopup;
