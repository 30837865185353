import Sidebar from "../../../app/components/sidebar";
import Header from "../../../app/components/header";
import styles from "../surveys.module.scss";
import React, {useEffect} from "react";
import {getFormattedDate} from "../../../utils";
import Table from "react-bootstrap/Table";
import sampleSurveyData from "../sample-surveys.json";
import {Link, useNavigate, useParams} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Form from 'react-bootstrap/Form';
import Searchbox from "../../../app/components/searchbox";
import {useState} from 'react';
import FiltersPopup from "../filters-popup";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import {fetchGetRequest, fetchPatchRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import debounce from "../../../utils/debounce";
import moment from "moment";

const SelectUsers = ({
}) => {
    const [assignedUsers, setAssignedUsers] = useState(null);
    const [surveyData, setSurveyData] = useState(null);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [filters, setFilters] = useState({})
    const [showFilters, setShowFilters] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState({});

    const [assigning, setAssigning] = useState(false);

    const {surveyId} = useParams();
    const navigate = useNavigate();

    const fetchSurveyData = async () => {
        try {
            const resp = await fetchGetRequest(endpoints.surveys.get(surveyId));
            if(resp.data.survey) {
                setSurveyData(resp.data.survey);
            }
        } catch (err) {
            console.error(err);
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        }
    }

    const fetchAssignedUsers = async () => {
        const keyword = searchInputValue;
        setAssignedUsers(null);
        try {
            const params = {...filters}
            if(keyword)
                params.keyword = keyword;
            console.log(params);
            const resp = await fetchGetRequest(endpoints.surveys.getAvailableUsers(surveyId), {params})
            if(Array.isArray(resp.data.surveyUsers)) {
                setAssignedUsers(resp.data.surveyUsers);
            }
        } catch(err) {
            console.error(err);
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        }
    }

    const assignToUsers = async () => {
        if(Object.keys(selectedUsers).length>0){
            setAssigning(true);
            try {
                const payload = {
                    surveyId,
                    userProcedureIds: Object.keys(selectedUsers)
                }
                const resp = await fetchPatchRequest(endpoints.surveys.assignToUsers, payload);
                if(resp.status < 400) {
                    toastMessage(constants.TOAST_TYPE.SUCCESS, resp?.data?.message);
                    navigate(`/survey/${surveyId}`);
                }
            } catch (err) {
                console.error(err);
                toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            } finally {
                setAssigning(false);
            }
        }else{
            setAssigning(false);
        }
       
    }

    const refresh = () => {
        setFilters(x => ({...x}));
    }

    const debouncedRefresh = debounce(refresh);

    useEffect(() => {
        fetchAssignedUsers();
    }, [filters]);

    useEffect(() => {
        fetchSurveyData();
    }, []);

    return (
        surveyData === null
        ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Spinner animation="border" />
                </div>
        :
        <div>
            {showFilters &&
                <FiltersPopup filters={filters}
                    onClose={() => setShowFilters(false)}
                    setFilters={setFilters}
                />}

            <div className="row">
                <div className="col-lg-12"
                     style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Link to={`/survey/${surveyId}/`} style={{display: 'flex', alignItems: 'center'}}>
                            <img src="/images/arrowhead-left.svg" className={styles.clickable}
                                 style={{marginRight: '32px', marginLeft: '10px'}}/>
                        </Link>
                        <h4 className={styles.contentTitle}>Select Users to assign {surveyData.title}</h4>
                    </div>
                    <div>
                        <button className={styles.primaryBtn} style={{padding: '8px 50px', paddingTop: '5px', fontWeight: 'normal'}}
                                onClick={() => assignToUsers()} disabled={assigning}>
                            {assigning &&
                                <Spinner animation="border" style={{marginRight: '20px'}}/>}
                            Assign
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <div style={{display: 'flex', gap: '15px'}}>
                            <img src="/images/filter_icon.svg" alt="Filter" className={styles.clickable}
                                 onClick={() => setShowFilters(true)}
                            />
                            <Searchbox value={searchInputValue} autoFocus
                                   disabled={assigning}
                                   onChange={(e) => {
                                       setSearchInputValue(e.target.value)
                                       debouncedRefresh();
                                   }}
                                   inputStyles={{width: '300px'}}
                                   onClick={() => refresh()}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {assignedUsers === null
            ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner animation="border" />
            </div>
            :
            <div className="row" style={{paddingTop: '20px'}}>
                <Form>
                    <Table responsive hover className={styles.usersListing}>
                        <thead>
                        <tr>
                            <th></th>
                            <th>User's Full Name</th>
                            <th>Procedure</th>
                            <th>Set</th>
                            <th>Date</th>
                            <th style={{width: '20%'}}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {assignedUsers.map(({user, procedure, procedureDateTime, procedureSet, userProcedureId}) =>
                            <tr key={user.userId} className={styles.hoverable}>
                                <td>
                                    <Form.Check size="xl" disabled={assigning}
                                        checked={assignedUsers?.[user.userId]}
                                        onChange={e => {
                                            if(e.target.checked)
                                                setSelectedUsers(x => ({
                                                    ...x, [userProcedureId]: true
                                                }));
                                            else
                                                setSelectedUsers(x => {
                                                    const y = {...x};
                                                    delete y[userProcedureId];
                                                    return y;
                                                })
                                        }}
                                    />
                                </td>
                                <td style={{verticalAlign: 'middle'}}>{user.fullName}</td>
                                <td style={{verticalAlign: 'middle'}}>{procedure.procedureName || "N/A"}</td>
                                <td style={{verticalAlign: 'middle'}}>{procedureSet.procedureSetName || "N/A"}</td>
                                <td style={{verticalAlign: 'middle'}}>{moment.utc(procedureDateTime).local().format("MM/DD/YYYY") || "N/A"}</td>
                                <td>
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>

                    {assignedUsers?.length === 0 &&
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'grey', paddingTop: '30px'}}>
                            No records!
                        </div>}
                </Form>
            </div>}
        </div>
    )
}

const SelectUsersPage = ({}) => {
    return (
        <div id="wrapper">
            <Sidebar/>
            <Header/>
            <section id="content-wrapper" className={styles.content}>
                <SelectUsers/>
            </section>
        </div>
    )
}

export default SelectUsersPage;
