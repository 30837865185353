import { Routes, Route } from "react-router-dom";
import { PrivateRoute, PublicRoute } from "./app/guards";
import { lazy } from 'react';
// const Login = lazy(() => import("./pages/login"));
//
//
//
// const ChangePassword = lazy(() => import("./pages/change-password"));
// const ForgottenPassword = lazy(() => import("./pages/forgotten-password"));
// const Procedures = lazy(() => import("./pages/procedures"));
// const ViewProcedurePage = lazy(() => import("./pages/procedures/view-procedure"));
// const FaqsAndTipsPage = lazy(() => import("./pages/faqs-and-tips"));
// const ViewFaqsAndTipsPage = lazy(() => import("./pages/faqs-and-tips/view-faqs-and-tips"));
// const UsersListingPage = lazy(() => import("./pages/users"));
// const ViewUser = lazy(() => import("./pages/users/view-user"));
// const SurveysListingPage = lazy(() => import("./pages/surveys"));
// const ViewSurveyPage = lazy(() => import("./pages/surveys/view-survey"));
// const SurveySelectUsersPage = lazy(() => import("./pages/surveys/select-users"));

import Login from "./pages/login";
import ChangePassword from "./pages/change-password";
import ForgottenPassword from "./pages/forgotten-password";
import Procedures from "./pages/procedures";
import ViewProcedurePage from "./pages/procedures/view-procedure";
import FaqsAndTipsPage from "./pages/faqs-and-tips";
import ViewFaqsAndTipsPage from "./pages/faqs-and-tips/view-faqs-and-tips";
import UsersListingPage from "./pages/users";
import ViewUser from "./pages/users/view-user";
import SurveysListingPage from "./pages/surveys";
import ViewSurveyPage from "./pages/surveys/view-survey";
import SurveySelectUsersPage from "./pages/surveys/select-users";



const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgottenPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<Procedures />} />
        <Route path="/procedure/:procedureId" element={<ViewProcedurePage />} />
        <Route path="/faqs-and-tips" element={<FaqsAndTipsPage />} />
        <Route path="/faqs-and-tips/:procedureId" element={<ViewFaqsAndTipsPage />} />
        <Route path="/users" element={<UsersListingPage />} />
        <Route path="/users/:userId" element={<ViewUser />} />
        <Route path="/surveys" element={<SurveysListingPage />} />
        <Route path="/survey/:surveyId" element={<ViewSurveyPage />} />
        <Route path="/survey/:surveyId/assign-users" element={<SurveySelectUsersPage />} />

        {/*<Route path="/required-item-management" element={<RequiredItemList />} />*/}
        {/*<Route path="/required-item-management/add" element={<AddRequiredItem />} />*/}
        {/*<Route*/}
        {/*  path="/required-item-management/edit/:id"*/}
        {/*  element={<EditRequiredItem />}*/}
        {/*/>*/}
        {/*<Route path="/county-management" element={<CountyList />} />*/}
        {/*<Route path="/county-management/add" element={<AddCounty />} />*/}
        {/*<Route path="/county-management/edit/:id" element={<EditCounty />} />*/}
        {/*<Route*/}
        {/*  path="/school-district-management"*/}
        {/*  element={<SchoolDistrictList />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/school-district-management/add"*/}
        {/*  element={<AddSchoolDistrict />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/school-district-management/edit/:id"*/}
        {/*  element={<EditSchoolDistrict />}*/}
        {/*/>*/}
        {/*<Route path="/item-size-management" element={<ItemSizeList />} />*/}
        {/*<Route path="/item-size-management/add" element={<AddItemSize />} />*/}
        {/*<Route*/}
        {/*  path="/item-size-management/edit/:id"*/}
        {/*  element={<EditItemSize />}*/}
        {/*/>*/}
        {/*<Route path="/school-management" element={<SchoolList />} />*/}
        {/*<Route path="/school-management/add" element={<AddSchool />} />*/}
        {/*<Route path="/school-management/edit/:id" element={<EditSchool />} />*/}
        {/*<Route path="/partner-management" element={<PartnerList />} />*/}
        {/*<Route path="/partner-management/add" element={<AddPartner />} />*/}
        {/*<Route path="/partner-management/edit/:id" element={<EditPartner />} />*/}
        {/*<Route path="/partner-management/view-volunteers/:id" element={<ViewPartnerVolunteers />} />*/}
        {/*<Route path="/volunteers" element={<Volunteers />} />*/}
        {/*<Route path="/educators" element={<Educators />} />*/}

        {/*<Route path="/form-placeholder-management" element={<FormPlaceholderManagement />} />*/}
        {/*<Route path="/form-placeholder-management/edit/:type" element={<EditFormPlaceholders />} />*/}

        {/*<Route path="/packets" element={<Packets />} />*/}
        {/*<Route path="/packets/unassigned" element={<UnassignedPacketsListing />} />*/}
        {/*<Route path="/packets/active" element={<ActivePacketsListing />} />*/}
        {/*<Route path="/packets/closed" element={<ClosedPacketsListing />} />*/}
        {/*<Route path="/packets/details/:packetId" element={<Viewpackets />} />*/}
        {/*<Route path="/packets/edit/:packetId" element={<EditPacket />} />*/}

        {/*  <Route path="/donors" element={<Donors />} />*/}
        {/*  <Route path="/viewpackets" element={<Viewpackets />} />*/}
        {/*  <Route path="/packets" element={<Packets />} />*/}
        {/*  <Route path="/notifications" element={<Notifications />} />*/}
        {/*  <Route path="/admin" element={<Admin />} />*/}
        {/*  <Route path="/admins" element={<Admins />} />*/}
        {/*  <Route path="/editforms" element={<Editforms />} />*/}
        {/*  <Route path="/editformsview" element={<Editformsview />} />*/}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
