
export function getFormattedDate(date_str) {
    if(!date_str) return undefined;
    let dt = date_str;
    if(typeof date_str === 'string') {
        const [Y, M, D] = date_str.split('-');
        dt = new Date(`${M}/${D}/${Y}`);
    }
    let DD = dt.getDate() + '';
    if(DD.length == 1) DD = '0' + DD;
    let MM = (dt.getMonth() + 1) + '';
    if(MM.length == 1) MM = '0' + MM;
    let YYYY = dt.getFullYear() + '';
    return `${MM}/${DD}/${YYYY}`;
}

export function getDateStringIn_YYYY_MM_DD(d) {
    let YYYY = d.getFullYear()
    let MM = (d.getMonth() + 1) + ''
    if(MM.length === 1) MM = '0' + MM;
    let DD = d.getDate() + ''
    if(DD.length === 1) DD = '0' + DD;
    return YYYY + "-" + MM + "-" + DD;
}


export function getTimeStr(dateTime){
    let HH = dateTime.getHours() + '';
    if(HH.length === 1) HH = '0' + HH;
    let MM = dateTime.getMinutes() + ''
    if(MM.length === 1) MM = '0' + MM;
    return HH + ":" + MM;
}
