import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {resetLoginData, signout} from "../../../store/reducers/loginData";
import {fetchDeleteRequest, fetchGetRequest} from "../../../utils/network";
import Spinner from "react-bootstrap/Spinner";
import endpoints from "../../../utils/endpoints";
import {Badge} from "react-bootstrap";
import LogoutConfirmationPopup from "./LogoutConfirmationPopup";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";

function NavCollapsible(image, label, children) {
  return {image, label, children}
}

function NavItem(image, label, link) {
  if(typeof link === "string") {
    return {image, label, link}
  }
  const onClick = link;
  return {image, label, onClick}
}


const SidebarItem = ({image, label, link, onClick, children, style}) => {
  const pathname = window.location.pathname;

  const isSelected = pathname?.trim() === link?.trim();
  const isChildSelected = children?.some(x => pathname?.trim() === x.link?.trim()) || false;
  const collapsibleClasses = Array.isArray(children) ? 'submenu__item _has-child' : '';
  const isActive = isSelected

  const [expanded, setExpanded] = useState(isChildSelected);

  return (
      <li style={style} onClick={() => {
        if(children) setExpanded(x => !x);
        onClick?.();
      }} className={`${collapsibleClasses} ${isActive ? 'active' : ''}`}>
        {(!Array.isArray(children) && link) ?
            <NavLink to={link}>
              <i>
                <img src={image} />
              </i>{" "}
              <font>{label}</font>
            </NavLink>
            : <span>
              <i>
                <img src={image} />
              </i>{" "}
              <font>{label}</font>
            </span>}
        {children &&
            <ul className={`submenu__child ${expanded ? "active" : ""}`}>
              {children.map((item, i) =>
                  <SidebarItem {...item} key={i} />)}
            </ul>
        }
      </li>
  )
}

const NotificationsCount = () => {
  // const [time, setTime] = useState(new Date());
  const [count, setCount] = useState("");

  const fetchCount = async () => {
    try {
      const response = await fetchGetRequest(
          endpoints.auth.meData
      )
      if(Number.isInteger(response.data.user.unreadNotificationsCount)) {
        setCount(response.data.user.unreadNotificationsCount);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    // const interval = setInterval(() => {
    //   setTime(new Date());
    //   setCount(localStorage.getItem("unread"))
    // }, 1000);
    //
    // return clearInterval(interval);
    fetchCount();

  }, [])

  return (
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center'
      }}>
        <span>Notifications </span>
        {count !== 0 &&
          <>
            {count === ""
              ? <div>
                  <Spinner animation="border" size="sm" />
                </div>
              : <div>
                <Badge bg="danger" pill style={{ display: 'inline' }}>
                  {count} <span className="visually-hidden">unread notifications</span>
                </Badge>
                </div>}
            </>}
      </div>
  )
}


const Sidebar = () => {
  const dispatch = useDispatch();
  const [ expanded, setExpanded ] = useState(localStorage.getItem("dynamic-management-expanded") || false);
  const navigate = useNavigate();
  const {procedureId} = useParams();
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);

  const logout = async () => {
    setLoggingOut(true);
    try {
      const resp = await fetchDeleteRequest(endpoints.auth.logout);
      await dispatch(signout());
      navigate("/login");
    } catch(err) {
      toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
      console.error(err);
    } finally {
      setLoggingOut(false);
    }
  };

  const toggleDynamicManagement = () => {
    setExpanded(x => !x)
    localStorage.setItem("dynamic-management-expanded", !expanded)
  }

  const sidebar_config = [
    // NavItem("/images/dashboard.svg", "Dashboard", "/"),
    // NavItem("/images/user-icon.svg", "Packet Management", "/packets"),
    // NavItem("/images/user-icon.svg", "Admins", "/admins"),
    // NavItem("/images/user-icon.svg", "Educators", "/educators"),
    // NavItem("/images/tag-icon.svg", "Volunteers", "/volunteers"),
    // NavCollapsible("/images/format_list_bulleted.svg", "Dynamic Management", [
    //   NavItem("/images/ballot.svg", "Required Item Management", "/required-item-management"),
    //   // NavItem("/images/aspect_ratio.svg", "Item Size Management", "/item-size-management"),
    //   NavItem("/images/flag_circle.svg", "County Management", "/county-management"),
    //   NavItem("/images/history_edu.svg", "School District Management", "/school-district-management"),
    //   NavItem("/images/school.svg", "School Management", "/school-management"),
    //   NavItem("/images/handshake.svg", "Partner Management", "/partner-management"),
    //   NavItem("/images/user-icon.svg", "Form Placeholder Management", "/form-placeholder-management"),
    // ]),
    // NavItem("/images/notification-bell.svg", <NotificationsCount />, "/notifications"),
    // NavItem("/images/log-icon.svg", "Logout", logout)
    NavItem("/images/users.svg", "Users", "/users"),
    NavItem("/images/procedures.svg", "Procedures", "/"),
    NavItem("/images/faqs-and-tips.svg", "FAQs and Tips", () => {
      if(procedureId) {
        if(window.location.pathname.startsWith("/procedure/")) {
          navigate(`/faqs-and-tips/${procedureId}`)
        } else {
          navigate("/faqs-and-tips");
        }
      } else {
        navigate("/faqs-and-tips");
      }
    }),
    NavItem("/images/surveys.svg", "Surveys", "/surveys"),
  ];
  

  return (
    <aside id="sidebar-wrapper">
      {showLogoutConfirmation &&
        <LogoutConfirmationPopup open={true} onLogout={() => logout()}
           loggingOut={loggingOut} onClose={() => setShowLogoutConfirmation(false)} />}

      {/*<div className="sidebar-brand">*/}
      {/*  <div className="d-flex">*/}
      {/*    <img alt="Young Automotive Group" src={"/images/small-logo.png"} style={{width: '50px', height: '50px'}} />*/}
      {/*    <h2>Upadr Admin</h2>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="scrollbar" id="style-1">
        <div className="force-overflow submenu" style={{display: 'flex'}}>
        <ul className="sidebar-nav submenu__main" style={{display: 'flex', flexDirection: 'column', flex:1}}>
          {sidebar_config.map((sidebar_item, i) =>
            <SidebarItem {...sidebar_item} key={i} />)}

            <li style={{flex: 1}}></li>
          <SidebarItem key={sidebar_config.length} style={{marginBottom:'25px'}}
               image="/images/logout.svg" label="Logout"
               onClick={() => setShowLogoutConfirmation(true)}
          />
        </ul>

        </div>
      </div>
    </aside>
  );
};
export default Sidebar;
