import styles from './addProcedurePopup.module.scss';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import React, {useState, useRef, useEffect} from 'react';
import procedures_styles from "../procedures.module.scss";
import DeleteProcedureConfirmationPopup from "../delete-procedure-confirmation-popup";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import {fetchDeleteRequest, fetchPostRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import Spinner from "react-bootstrap/Spinner";
import {timeStrToWhen, whenToTimeStr} from "../../../utils/when";


export const RenderProcedureStepForm = ({heading, stepData, setStepData, isAdd}) => {
    const fileInputRef = useRef();
    const [backgroundImage, setBackgroundImage] = useState(stepData?.procedureStepImageUrl || null);
    const [when, setWhen] = useState(timeStrToWhen(stepData?.when || "01:00:00"));
    const updateProp = (prop, value) => {
        setStepData(x => ({
            ...x,
            [prop]: value
        }))
    }
    const onImageSelect = (e) => {
        const file = e.target?.files?.[0];
        if (!file) return;
    
        if (file && file.type.startsWith("image/")) {
          const img = new Image();
          img.src = URL.createObjectURL(file);
    
          img.onload = () => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
    
            const aspectRatio = width / height;
    
            // Check if the aspect ratio is 16:9
            if (Math.abs(aspectRatio - 16 / 9) < 0.01) {
              updateProp("procedureStepImage", file);
              setBackgroundImage("none");
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                const fileContent = reader.result;
                setBackgroundImage(`url(${fileContent})`);
              };
            } else {
              toastMessage(
                constants.TOAST_TYPE.ERROR,
                "Please upload an image with a 16:9 aspect ratio."
              );
              e.target.value = "";
            }
    
            // Clean up the URL object
            URL.revokeObjectURL(img.src);
          };
        } else {
          toastMessage(
            constants.TOAST_TYPE.ERROR,
            "Selected file is not an image."
          );
          e.target.value = "";
        }
      };
    
    // const onImageSelect = e => {
    //     const file = e.target?.files?.[0];
    //     if(!file) return;
    //     if (file && file.type.startsWith('image/')) {
    //         updateProp('procedureStepImage', file);
    //         setBackgroundImage('none')
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => {
    //             const fileContent = reader.result;
    //             setBackgroundImage(`url(${fileContent})`);
    //         }
    //     } else {
    //         toastMessage(constants.TOAST_TYPE.ERROR,"Selected file is not an image.");
    //         e.target.value = '';
    //     }
    // }

    const countOptions = Array(30).fill().map((_, index) => index + 1);
    const duration_names = ["Day", "Week", "Hour"];

    const filters = {
        'day': (x) => x <= 30,
        'week': (x) => x <= 4,
        'hour': (x) => x <= 24
    }

    const updateCount = (e) => {
        const newCount = parseInt(e.target.value);
        setWhen(x => ({ ...x, count: newCount }))
    }

    const updateDurationName = (e) => {
        const newDurationName = e.target.value;
        setWhen(x => {
            let newCount = x.count;
            const counts_arr = countOptions.filter(filters[newDurationName]);
            if(counts_arr[counts_arr.length-1] > x.count) {
                newCount = 1;
            }
            const xx = ({...x, duration_name: newDurationName, count: newCount})
            return xx;
        })
    }

    useEffect(() => {
        updateProp("when", whenToTimeStr(when.count, when.duration_name));
    }, [when]);

    return (
        <div style={{marginBottom: '30px'}}>
            <h5 style={{marginBottom: '8px'}}>{heading}</h5>
            <div style={{display: 'flex', gap: '10px', alignItems: 'stretch'}}>
                <div style={{flex:1}}>
                    <Form.Group className="mb-3">
                        <Form.Label style={{color: '#8E8E8E'}}>When</Form.Label>
                        <div style={{display: 'flex', gap: '12px'}}>
                            <Form.Select value={when.count} onChange={updateCount}>
                                {countOptions.filter(filters[when.duration_name])
                                    .map(val =>
                                    <option value={val}>{val}</option>)}
                            </Form.Select>
                            <Form.Select value={when.duration_name} onChange={updateDurationName}>
                                {duration_names.map(val =>
                                    <option value={val.toLowerCase()}>{val}{when.count > 1 && "s"}</option>)}
                            </Form.Select>
                            <Form.Select value={stepData?.isBeforeProcedure ? 'true' : 'false'}
                                 onChange={e =>
                                     updateProp("isBeforeProcedure", e.target.value === 'true')}>
                                <option value="true">Before</option>
                                <option value="false">After</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label style={{color: '#8E8E8E'}}>Description</Form.Label>
                        <Form.Control style={{color: '#8E8E8E'}} required as="textarea" rows={3} placeholder="Description" value={stepData.description}
                              onChange={e => updateProp('description', e.target.value)}
                        />
                    </Form.Group>
                </div>
                <Form.Group style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                    <Form.Label style={{color: '#8E8E8E'}}>Image</Form.Label>
                    <div className={styles.clickable} style={{width:"334px",
                        display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1,
                        boxShadow: '0px 4px 4px 0px #00000040', backgroundPositionX: 'center', backgroundPositionY: 'center',
                        backgroundSize: 'contain', backgroundRepeat: 'no-repeat',
                        backgroundImage: stepData.procedureStepImage instanceof File
                            ? backgroundImage : (stepData?.procedureStepImageUrl ? `url(${stepData.procedureStepImageUrl})`
                                : 'unset')
                    }} onClick={() => fileInputRef.current?.click()}>
                        <input type="file" accept="image/*" style={{opacity: 0, width: '1px'}}
                               ref={fileInputRef} onChange={onImageSelect} required={!backgroundImage} />
                        <img src={backgroundImage ? "/images/blue-editicon.svg" : "/images/blue-plusicon.svg"} />
                    </div>
                </Form.Group>
            </div>
        </div>
    )
}


const AddProcedurePopup = ({open, onClose, onAdd}) => {
    const [step, setStep] = useState(0);
    const [savingData, setSavingData] = useState(false);
    const [canceling, setCanceling] = useState(false);
    const [procedure, setProcedure] = useState({
        status: 'Active', title: '', steps: []
    });

    const addProcedure = async () => {
        setSavingData(true);
        try {
            const resp = await fetchPostRequest(endpoints.procedures.create, {
                'status': procedure.status, 'title': procedure.title
            });
            setProcedure(x => ({
                ...x, title: procedure.title, status: procedure.status, procedureId: resp.data.procedureId
            }))
            appendStep();
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setSavingData(false);
        }
    }

    const deleteProcedure = async () => {
        setCanceling(true);
        try {
            const resp = await fetchDeleteRequest(endpoints.procedures.delete(procedure.procedureId))
            if(resp.status < 400) {
                // toastMessage(constants.TOAST_TYPE.SUCCESS, resp.data.message);
            }
            onClose?.();
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setCanceling(false);
        }
    }

    const appendStep = () => {
        setProcedure(x => {
            const y = {...x};
            y.steps.push({when: '01:00:00', isBeforeProcedure: false, description: '', procedureStepImage: null})
            return y;
        })
        setStep(x => x + 1);
    }

    const addStep = async (onSuccess) => {
        setSavingData(true);
        const lastStep = procedure.steps[procedure.steps.length - 1];
        const payload = new FormData();
        payload.append("procedureId", procedure.procedureId);
        payload.append("when", lastStep.when);
        payload.append("isBeforeProcedure", lastStep.isBeforeProcedure);
        payload.append("description", lastStep.description);
        payload.append("procedureStepImage", lastStep.procedureStepImage);
        try {
            const resp = await fetchPostRequest(endpoints.procedures.steps.create, payload);
            if(resp.status < 400) {
                toastMessage(constants.TOAST_TYPE.SUCCESS, resp.data.message);
                appendStep();
            }
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setSavingData(false);
        }
    }

    const setStepData = (x) => {
        if(typeof x === 'function') {
            setProcedure(y => {
                const z = {...y};
                z.steps[z.steps.length - 1] = x(z.steps[z.steps.length - 1]);
                return z;
            })
        } else {
            setProcedure(y => {
                const z = {...y};
                z.steps[z.steps.length - 1] = x;
                return z;
            })
        }
    }

    return (
        <>
            <Modal show={open} size="lg" scrollable>
                <Modal.Header style={{borderBottom: 'none'}}>
                    <Modal.Title>Add a Procedure</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="add-procedure-form" onSubmit={e => {
                        e.preventDefault(); e.stopPropagation();
                        if(step === 0) {
                            addProcedure();
                        } else {
                            addStep()
                        }
                    }}>
                        <Form.Group className="mb-1">
                            <Form.Label>Procedure Title</Form.Label>
                            <Form.Control type="text" placeholder="Procedure Title" readOnly={step > 0} required={step === 0}
                                  value={procedure.title} onChange={e => setProcedure(x => ({...x, title: e.target.value}))}
                            />
                        </Form.Group>
                        {step > 0 &&
                            <RenderProcedureStepForm isAdd={true} key={step} heading={`Step ${step}`}
                                 stepData={procedure.steps[procedure.steps.length - 1]} setStepData={setStepData}
                            />}
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{display: 'flex', justifyContent: 'center', borderTop: 'none'}}>
                    {step > 0 &&
                        <>
                            <button className={procedures_styles.secondaryBtn} onClick={() => deleteProcedure()}
                                    disabled={savingData || canceling} style={{
                                padding: '5px 30px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                            }}>
                                {canceling &&
                                    <Spinner animation="border" style={{marginRight: '20px'}}/>}
                                Cancel
                            </button>
                            <button className={procedures_styles.primaryBtn} style={{
                                padding: '5px 30px', background: '#4DCDCD', borderColor: '#4DCDCD',
                                display: 'flex', alignItems: 'center', justifyContent: 'center'
                            }} form="add-procedure-form" disabled={savingData}>
                                {savingData &&
                                    <Spinner animation="border" style={{marginRight: '20px'}}/>}
                                Add Step
                            </button>
                            {step > 1 &&
                                <button className={procedures_styles.primaryBtn} style={{padding: '5px 30px'}}
                                        onClick={onAdd}>Done
                                </button>}
                        </>}
                    {step === 0 &&
                        <>
                            <button className={procedures_styles.secondaryBtn} style={{padding: '5px 30px'}}
                                    onClick={onClose}>Cancel</button>
                            <button form="add-procedure-form" type="submit" style={{
                                padding: '5px 30px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                            }}
                                    disabled={savingData || canceling}
                                    className={procedures_styles.primaryBtn}>
                                {savingData &&
                                    <Spinner animation="border" style={{marginRight: '20px'}}/>}
                                Next
                            </button>
                        </>}
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default AddProcedurePopup;
