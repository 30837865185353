import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Spinner from "react-bootstrap/Spinner";
import styles from "./setSelector.module.scss"; // Assuming you use SCSS modules
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import { fetchPatchRequest } from "../../../utils/network";
import endpoints from "../../../utils/endpoints";

// 'url("/images/darkPencilIcon.svg")'

const SetSelector = ({
   setsData, onChange, procedureId, currentSet, editModeOn, onEditSet
}) => {
    const [saving, setSaving] = useState(false);

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {saving
                ?
                <Spinner animation="border" />
                :
                (editModeOn
                    ?   <div className={styles.currentStatus} onClick={() => {
                        if(currentSet.procedureSetId)
                            onEditSet();
                    }}>
                        <div>{currentSet?.setName}</div>
                        {currentSet.procedureSetId &&
                            <img src="/images/darkPencilIcon.svg" />}
                    </div>
                    :
                    <div className={styles.customSelectContainer}>
                    <Form.Select
                        className={`${styles.customSelect} ${styles.procedureSetSelector}`}
                        value={currentSet?.procedureSetId || ""}
                        onChange={e => {
                            const newSet = setsData.find(x => x.procedureSetId === e.target.value);
                            if (newSet) onChange(newSet);
                        }}
                        style={{
                            backgroundImage: 'url("/images/black-dropdown-arrow.svg")',
                        }}
                    >
                        {setsData.map(setData =>
                            <option key={setData.procedureSetId} value={setData.procedureSetId}>{setData.setName}</option>
                        )}
                    </Form.Select>
                    <div className={styles.selectedText}>
                        {currentSet?.setName || "Select a set"}
                    </div>
                </div>)
            }
        </div>
    )
}

export default SetSelector;
