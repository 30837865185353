import React, {useEffect, useState} from "react";
import styles from "./searchbox.module.scss";
import debounce from "../../../utils/debounce";

const SearchBox = ({
    value, onChange, autoFocus, onClick, inputStyles, disabled
}) => {
    const [keyword, setKeyword] = useState(value);
    const debouncedOnChange = debounce(onChange, 1000);

    return (
        <div className={styles.SearchBox}>
            <input style={{
                    padding: '8px 15px', borderRadius: 0, border: '1px solid #8E8E8E', borderRight: 'none',
                    ...inputStyles
                }}
                type="text" placeholder="Search" autoFocus={!!autoFocus}
                value={keyword} onChange={e => {
                    debouncedOnChange(e);
                    setKeyword(e.target.value)
                }} disabled={!!disabled}
            />
            <button style={{padding: '8px 15px', border: '1px solid #8E8E8E', borderLeft: 'none', background: 'transparent'}} onClick={onClick} disabled={!!disabled}>
                <img src="/images/search-icon.svg" alt="search" />
            </button>
        </div>
    )
}

export default SearchBox;
