import Modal from "react-bootstrap/Modal";
import styles from "../surveys.module.scss";
import Form from "react-bootstrap/Form";
import filter_styles from "../filters-popup/filterPopup.module.scss";
import React, { useEffect, useState } from "react";
import { SliderAnswer } from "../view-survey/QuestionItem";
import { fetchGetRequest } from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import { Spinner } from "react-bootstrap";

const McqAnswerView = ({ ...questionData }) => {
  return (
    <>
      <p
        style={{
          color: "#666666",
          fontSize: "18px",
          fontWeight: 400,
          marginBottom: 0,
        }}
      >
        Answers
      </p>
      <div style={{ paddingLeft: "20px" }}>
        {questionData?.givenAnswerCounts?.map((optionItem, i) => (
          <div style={{ paddingBottom: "8px" }}>
            <span>{optionItem?.option}</span>
            <span style={{ color: "#013399", fontWeight: "bold" }}>
              {" "}
              ({optionItem?.count || 0})
            </span>
            {i < questionData.options.length - 1 ? <br /> : null}
          </div>
        ))}
      </div>
    </>
  );
};

const ShortAnswerView = ({ ...questionData }) => {
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [shortAnswer, setShortAnswer] = useState(null);
  const [loading, setLoading] = useState(false);
  const previousPageExists = () => page > 1;
  const nextPageExists = () => page < lastPage;

  const getShortAnswers = async (surveyQuestionId, page) => {
    setLoading(true);
    try {
      const response = await fetchGetRequest(
        endpoints.surveys.getSurveyShortAnswer(surveyQuestionId, page)
      );
      if (response.status === 200) {
        setShortAnswer(response.data);
        setLastPage(response?.data?.pagination?.lastPage);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (questionData?.surveyQuestionId) {
      getShortAnswers(questionData?.surveyQuestionId, page);
    }
  }, [page]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p
          style={{
            color: "#666666",
            fontSize: "18px",
            fontWeight: 400,
            marginBottom: 0,
          }}
        >
          Answers
        </p>
        <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          {previousPageExists() ? (
            <img
              src="/images/enabled_arrow_right.svg"
              alt="arrow left enabled"
              className={styles.clickable}
              style={{ transform: "rotate(-0.5turn)" }}
              onClick={() => setPage((x) => x - 1)}
            />
          ) : (
            <img
              src="/images/disabled_arrow_left.svg"
              alt="arrow left disabled"
            />
          )}
          <div style={{ color: "#CBCBCB", marginBottom: 0 }}>
            {page} of {lastPage}
          </div>
          {nextPageExists() ? (
            <img
              src="/images/enabled_arrow_right.svg"
              alt="arrow left enabled"
              className={styles.clickable}
              onClick={() => setPage((x) => x + 1)}
            />
          ) : (
            <img
              src="/images/disabled_arrow_left.svg"
              alt="arrow left disabled"
              style={{ transform: "rotate(-0.5turn)" }}
            />
          )}
        </div>
      </div>
      {loading ? (
       <div style={{
        display: "flex",
        justifyContent: "center",
      }}>
         <Spinner animation="border" />
       </div>
      ) : (
        <div style={{ paddingLeft: "20px" }}>
          {shortAnswer?.answers?.map((answerItem, i) => (
            <div style={{ paddingBottom: "15px" }}>
              <span style={{ fontWeight: "bold", color: "#5A5A5A" }}>
                {answerItem?.userName}:{" "}
              </span>
              <span style={{ color: "#5A5A5A" }}>
                {" "}
                ({answerItem?.givenAnswer})
              </span>
              {i < shortAnswer?.answers?.length - 1 ? <br /> : null}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const SliderAnswerView = ({ ...questionData }) => {
  return (
    <>
      <p
        style={{
          color: "#666666",
          fontSize: "18px",
          fontWeight: 400,
          marginBottom: 0,
        }}
      >
        Answers
      </p>
      <SliderAnswer {...questionData} />
    </>
  );
};

const QuestionView = ({ questionData, onClose, onEdit, heading }) => {
  const showQuestionType = () => {
    const questionType = questionData.questionType;
    if (questionType === "single-choice") {
      return "Multiple Choice - Select one";
    } else if (questionType === "multiple-choice") {
      return "Multiple Choice - Select Multiple";
    } else if (questionType === "slider") {
      return "Slider";
    } else if (questionType === "short-answer") {
      return "Short Answer";
    }
  };

  return (
    <Modal show={true} scrollable size="lg">
      <Modal.Header
        style={{
          borderBottom: "none",
          display: "flex",
          alignItems: "center",
          paddingTop: "40px",
          paddingBottom: "20px",
          paddingLeft: "40px",
          paddingRight: "40px",
        }}
      >
        {/*<Modal.Title style={{textAlign: 'left', width: '100%'}}>Filter By</Modal.Title>*/}
        <div style={{ display: "flex", alignItems: "center" }}>
          <h5 style={{ fontSize: "25px" }}>
            {heading} Results ({questionData?.totalAnsweredBy || 0})
          </h5>
          <h6
            style={{
              marginBottom: 0,
              paddingLeft: "20px",
              color: "#5A5A5A",
            }}
          >
            {showQuestionType()}
          </h6>
        </div>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "250px", maxHeight: "90vh" }}>
        <div style={{ paddingLeft: "25px", paddingRight: "25px" }}>
          <h5 style={{ color: "#666666" }}>{heading}</h5>
          <p style={{ color: "#5A5A5A", paddingLeft: "20px" }}>
            {questionData.question}
          </p>
          {questionData.questionType === "single-choice" ||
          questionData.questionType === "multiple-choice" ? (
            <McqAnswerView {...questionData} />
          ) : questionData.questionType === "short-answer" ? (
            <ShortAnswerView {...questionData} />
          ) : questionData.questionType === "slider" ? (
            <SliderAnswerView {...questionData} />
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "flex-end",
          borderTop: "none",
        }}
      >
        <button
          className={styles.primaryBtn}
          style={{
            padding: "5px 30px",
            width: "180px",
          }}
          onClick={onEdit}
        >
          Edit
        </button>
        <button
          className={styles.secondaryBtn}
          style={{
            padding: "5px 0px",
            width: "180px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={onClose}
        >
          Dismiss
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default QuestionView;
