import Modal from "react-bootstrap/Modal";
import procedures_styles from "../procedures.module.scss";
import React from "react";
import styles from "./expandImagePopup.module.scss";

const ExpandImagePopup = ({
    imageURL, onClose
}) => {

    return (
        <Modal show={true} size="lg" className={styles.expandImagePopup}>
            <Modal.Header style={{borderBottom: 'none', display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                <img className={procedures_styles.clickable} src="/images/close-icon.png"
                     style={{width: '30px', height: '30px'}} onClick={onClose} />
            </Modal.Header>
            <Modal.Body>
                <div
                     style={{
                         backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center',
                         backgroundImage: `url(${imageURL})`, width: '100%', height: '400px', maxHeight: '50rem'
                     }}>
                </div>
            </Modal.Body>
        </Modal>
    )
}


export default ExpandImagePopup;
