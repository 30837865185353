/**
 * timeStr: "01:00:00"
 * when: 1, "day"
 * orderableNumber: -inf, ..., -5, ..., 0, ..., 3, +inf
 * readableString: 1 hour before procedure
 * duration_name: 'day', 'week', 'hour'
 */

export function timeStrToOrderableNumber(time_str, isBeforeProcedure) {
    const [hours_str, minutes_str, seconds_str] = time_str.split(":")
    let seconds = (parseInt(hours_str) * 60 * 60) + (parseInt(minutes_str) * 60) + parseInt(seconds_str)
    if(isBeforeProcedure) seconds = seconds * -1;
    return seconds;
}

export function whenToTimeStr(count, duration_name) {
    const duration_lookup = {
        'day': 60 * 60 * 24, 'week': 60 * 60 * 24 * 7, 'hour': 60 * 60
    }
    let seconds = count * duration_lookup[duration_name.toLowerCase()];
    let minutes = 0;
    let hours = 0;
    if(seconds >= 60) {
        minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;
    }
    if(minutes >= 60) {
        hours = Math.floor(minutes / 60);
        minutes = minutes % 60;
    }
    let seconds_str = seconds + '';
    if(seconds_str.length < 2) seconds_str = '0' + seconds_str;
    let minutes_str = minutes + '';
    if(minutes_str.length < 2) minutes_str = '0' + minutes_str;
    let hours_str = hours + '';
    if(hours_str.length < 2) hours_str = '0' + hours_str;
    return [hours_str, minutes_str, seconds_str].join(":");
}

export function timeStrToWhen(time_str) {
    const duration_lookup = [
        ['week', 60 * 60 * 24 * 7], ['day', 60 * 60 * 24], ['hour', 60 * 60]
    ]
    let [hours, minutes, seconds] = time_str.split(":").map(x => parseInt(x))
    seconds = (hours * 60 * 60) + (minutes * 60) + seconds
    if(seconds === 0) return {count: 1, duration_name: 'hour'};
    for(const [duration_name, duration] of duration_lookup) {
        if(seconds % duration === 0) {
            return {count: seconds / duration, duration_name}
        }
    }
    return {count: 1, duration_name: 'hour'}
}

export function timeStrToReadableString(timeStr, isBeforeProcedure) {
    const when = timeStrToWhen(timeStr);
    return when.count + " " + when.duration_name + (when.count > 1 ? 's' : '') + ' ' +
        (isBeforeProcedure ? 'before' : 'after') + ' ' + 'procedure';
}
