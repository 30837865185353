import Form from 'react-bootstrap/Form';
import {useState} from "react";
import styles from "./surveyStatusSelector.scss";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import {fetchPatchRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import Spinner from "react-bootstrap/Spinner";

const SurveyStatusSelector = ({
    surveyData, onChange
}) => {
    const [status, setStatus] = useState(surveyData.status);
    const [saving, setSaving] = useState(false);

    const getStylesByStatus = (status) => {
        if(status === 'Active') {
            return {
                color: 'white', backgroundColor: '#4DCDCD', appearance: 'none',
                backgroundImage: 'url("/images/white-dropdown-arrow.svg")', backgroundSize: '10px 10px'
            };
        } else if(status === 'Deactivated') {
            return {
                color: 'white', backgroundColor: '#013399', appearance: 'none',
                backgroundImage: 'url("/images/white-dropdown-arrow.svg")', backgroundSize: '10px 10px'
            }
        } else if(status === 'Paused') {
            return {
                color: '#013399', backgroundColor: '#ECFAFE', appearance: 'none',
                backgroundImage: 'url("/images/blue-dropdown-arrow.svg")', backgroundSize: '10px 10px'
            }
        }
        return {}
    }

    const updateStatus = async (newStatus) => {
        setSaving(true);
        try {
            const resp = await fetchPatchRequest(endpoints.surveys.updateStatus(surveyData.surveyId), {
                status: newStatus
            })
            onChange(({
                ...surveyData, status: newStatus
            }))
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setSaving(false);
        }
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {saving
            ?
                <Spinner animation="border" />
            :
                <Form.Select style={{
                        width: 'auto', borderRadius: '100px', padding: '5px 10px', minWidth: '150px',
                        ...getStylesByStatus(status)
                    }} value={status} className={styles.procedureStatusSelector}
                    onChange={e => {
                        updateStatus(e.target.value)
                    }}>
                    <option value="Active">Active</option>
                    <option value="Deactivated">Deactivated</option>
                    <option value="Paused">Paused</option>
                </Form.Select>}
        </div>
    )
}



export default SurveyStatusSelector;
