import { ErrorMessage } from "formik";
import React from "react";
import styles from "./inputErrorMessage.module.scss";

const InputErrorMessage = ({ name, ...rest }) => {
  return (
    <span className={styles.errorMessageText} {...rest}>
      <ErrorMessage name={name} />
    </span>
  );
};

export const CustomInputErrorMessage = ({ msg }) => {
    return (
        msg ?
            <span className={styles.errorMessageText}>{msg}</span>
            : null
    )
}


export default InputErrorMessage;
