import "../../assets/styles/dashboard.css";
import Sidebar from "../../app/components/sidebar";
import Header from "../../app/components/header";
import React, {useEffect, useState} from "react";
import styles from "./surveys.module.scss";
import {Link} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import toastMessage from "../../utils/toastMessage";
import constants from "../../utils/constants";
import InfiniteScroll from 'react-infinite-scroller';
import sampleSurveysData from './sample-surveys.json';
import SurveyStatusSelector from "./SurveyStatusSelector";
import DeleteSurveyConfirmationPopup from "./delete-survey-confirmation-popup";
import AddSurveyPopup from "./add-survey-popup";
import endpoints from "../../utils/endpoints";
import {fetchGetRequest} from "../../utils/network";
import EditSurveyTitlePopup from "./edit-survey-title-popup";


const SurveyItem = ({data, onDelete, onEdit, onChange}) => {
    const {surveyId, title, numberOfSteps, status} = data;
    return (
        <div className={styles.procedureCard}>
            <Link className={styles.info} to={`/survey/${surveyId}`} style={{flex:1, display: 'flex', alignItems: 'center'}}>
                <h5 className={styles.surveyListTitle} style={{ wordBreak: 'break-word'}}>{title}</h5>
                {/*<p>{numberOfSteps || 0} {numberOfSteps === 1 ? "step" : "steps"}</p>*/}
            </Link>
            <SurveyStatusSelector surveyData={data} onChange={onChange} />
            <div className={styles.clickable} onClick={onEdit} style={{
                display: 'flex', alignItems: 'center', marginLeft: '15px', marginRight: '15px'
            }}>
                <img src="/images/edit-pencil.svg"/>
            </div>
            <div className={styles.clickable} onClick={onDelete} style={{
                display: 'flex', alignItems: "center"
            }}>
                <img src="/images/dustbin.svg" />
            </div>
        </div>
    )
}

const Surveys = ({}) => {
    const [surveysData, setSurveysData] = useState(null);
    const [showAddSurvey, setShowAddSurvey] = useState(false);
    const [showEditSurvey, setShowEditSurvey] = useState(null);
    const [showDeleteSurvey, setShowDeleteSurvey] = useState('');

    const [pagination, setPagination] = useState({page: 1, size: 50});
    const [lastPage, setLastPage] = useState(1);

    const [loadingMore, setLoadingMore] = useState(false);

    const fetchProcedures = async () => {
        setLoadingMore(true);
        try {
            const resp = await fetchGetRequest(endpoints.surveys.getAll, {params: pagination})
            if(Array.isArray(resp.data.surveys)) {
                if(resp.data.pagination.lastPage) setLastPage(resp.data.pagination.lastPage);

                setSurveysData(x => {
                    const y = [...(x || [])];
                    for(let dt of resp.data.surveys) {
                        if(!y.some(z => z.surveyId === dt.surveyId)) y.push(dt);
                    }
                    y.sort((a, b) => {
                        const a_ = a.title.toLowerCase();
                        const b_ = b.title.toLowerCase();
                        if (a_ < b_)
                            return -1;
                        if (a_ > b_)
                            return 1;
                        return 0;
                    });
                    console.log("new_y: ", y);
                    return y;
                });
            }
        } catch(err) {
            console.error(err);
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        } finally {
            setLoadingMore(false);
        }
    }

    const switchPage = newPage => {
        setPagination(x => ({
            ...x, page: newPage
        }))
    }

    const refresh = () => {
        setSurveysData(null);
        setLastPage(1);
        setPagination({page: 1, size: 50});
        fetchProcedures();
    }

    useEffect(() => {
        fetchProcedures();
    }, [pagination]);

    return (
        surveysData === null
            ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner animation="border" />
            </div>
            :
            <InfiniteScroll
                pageStart={1}
                loadMore={switchPage}
                hasMore={pagination.page < lastPage}
                loader={null}
                useWindow={true}
            >
                {showDeleteSurvey &&
                    <DeleteSurveyConfirmationPopup
                        open={!!showDeleteSurvey} onClose={() => setShowDeleteSurvey(null)}
                        onDelete={() => {
                            setShowDeleteSurvey(null);
                            refresh();
                        }} surveyId={showDeleteSurvey.surveyId}
                    />}

                {showAddSurvey &&
                    <AddSurveyPopup
                        onClose={() => {
                            setShowAddSurvey(false);
                        }}
                        onAdd={() => {
                            setShowAddSurvey(false);
                            refresh();
                        }}
                    />}

                {showEditSurvey &&
                    <EditSurveyTitlePopup
                        surveyData={showEditSurvey}
                        onSave={() => {
                            setShowEditSurvey(null);
                            refresh();
                        }}
                        onClose={() => setShowEditSurvey(null)}
                    />}

                <div className="row">
                    <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className={styles.contentTitle}>Surveys</h2>
                        <div>
                            <img src="/images/blue-plusicon.svg" className={styles.clickable}
                                 style={{height: '40px', width: 'auto', marginRight: '15px'}}
                                 onClick={() => setShowAddSurvey(true)}/>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {surveysData.map(surveyItem =>
                        <div className="col-lg-6" style={{padding: '8px 8px'}}>
                        <SurveyItem data={surveyItem} onEdit={() => setShowEditSurvey(surveyItem)}
                                   key={`${surveyItem.procedureId}:${surveyItem.status}`}
                                   onDelete={() => setShowDeleteSurvey(surveyItem)}
                                   onChange={newSurveyData => {
                                       setSurveysData(x =>
                                           x.map(yItem => yItem.surveyId === newSurveyData.surveyId ? newSurveyData : yItem)
                                       )
                                   }}
                            />
                        </div>)}
                </div>

                {surveysData?.length === 0 &&
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'grey', paddingTop: '30px'}}>
                        No records!
                    </div>}

                {loadingMore &&
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Spinner animation="border"/>
                    </div>}

            </InfiniteScroll>
    )
}


const SurveysPage = ({}) => {

    return (
        <div id="wrapper">
            <Sidebar/>
            <Header />
            <section id="content-wrapper" className={styles.content}>
                <Surveys />
            </section>
        </div>
    )
}


export default SurveysPage;
