import DeleteUser from "../delete-user";
import EditUser from "../edit-user";
import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import users_styles from "../users.module.scss";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import KVPair from "./KVPair";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import sampleUsersData from "../users-data.json";
import TaskList from "./TaskList";
import SurveyList from "./SurveyList";
import FaqAndTipsList from "./FaqAndTipsList";
import Sidebar from "../../../app/components/sidebar";
import Header from "../../../app/components/header";
import styles from "../users.module.scss";
import Searchbox from "../../../app/components/searchbox";
import {fetchGetRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import {useNavigate, useParams} from "react-router-dom";
import {getFormattedDate, getTimeStr} from '../../../utils';
import CancelProcedure from "../cancel-procedure";
import {timeStrToOrderableNumber, timeStrToReadableString} from "../../../utils/when";
import moment from "moment";

const ViewUser = ({
    open, onClose
}) => {
    const [userDetails, setUserDetails] = useState(null);
    const [showDeleteUserModal, setShowDeleteUserModal] = React.useState(null);
    const [showEditUserModal, setShowEditUserModal] = React.useState(null);

    const [showCancelProcedure, setShowCancelProcedure] = React.useState(null);

    const {userId} = useParams();
    const navigate = useNavigate();

    const fetchUserData = async () => {
        try {
            const resp = await fetchGetRequest(endpoints.users.getUser(userId));
            if(resp.data.user) {
                setUserDetails(resp.data.user.procedures);
                for(let i=0; i<resp.data.user.procedures.length; i++) {
                    let proc = resp.data.user.procedures[i];
                    proc.tips = proc.tips.sort((a, b) => {
                        const a_ = timeStrToOrderableNumber(a.when, a.isBeforeProcedure);
                        const b_ = timeStrToReadableString(b.when, b.isBeforeProcedure);
                        if (a_ < b_)
                            return -1;
                        if (a_ > b_)
                            return 1;
                        return 0;
                    })
                }
                setUserDetails(resp.data.user);
            }
        } catch (err) {
            console.error(err);
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        }
    }

    useEffect(() => {
        setShowEditUserModal(null);
        setShowDeleteUserModal(null);
        fetchUserData();
    }, []);

    return (
        <div className="row">
            {showDeleteUserModal &&
                <DeleteUser open={!!showDeleteUserModal} userId={showDeleteUserModal.userId}
                    onClose={() => setShowDeleteUserModal(null)}
                    onDelete={() => {
                        setShowDeleteUserModal(null);
                        navigate('/users');
                    }}
                />}

            {showEditUserModal &&
                <EditUser open={!!showEditUserModal} userData={showEditUserModal}
                      onSuccess={() => {
                          setShowEditUserModal(null);
                          fetchUserData();
                      }}
                      onClose={() => setShowEditUserModal(null)}
                />}

            {showCancelProcedure &&
                <CancelProcedure open={!!showCancelProcedure} userProcedureId={showCancelProcedure}
                     onSuccess={() => {
                         setShowCancelProcedure(null);
                         setUserDetails(null);
                         fetchUserData();
                     }}
                     onClose={() => setShowCancelProcedure(null)}
                />}

            {/*<Modal show={!showDeleteUserModal && !showEditUserModal && open} scrollable size="lg">*/}
            {/*    <Modal.Header style={{borderBottom: 'none', display: 'flex', alignItems: 'flex-start', marginTop: '20px', marginLeft: '15px', marginRight: '15px'}}>*/}
            {/*        <Modal.Title style={{*/}
            {/*            color: '#363636', textAlign: 'left', width: '100%', fontWeight: 'bold'*/}
            {/*        }}>View User</Modal.Title>*/}
            {/*        <img className={users_styles.hoverable} src="/images/close-icon.png"*/}
            {/*             style={{width: '30px', height: '30px'}} onClick={onClose} />*/}
            {/*    </Modal.Header>*/}
            {/*    <Modal.Body style={{marginRight: '15px', marginLeft: '15px'}}>*/}
            <div className="col-lg-12">
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h2 className={styles.contentTitle} style={{marginBottom: '40px'}}>View User</h2>
                    <div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '15px'}}>
                            <button style={{
                                background: '#D30B0B', borderRadius: '100px', border: 'none',
                                width: '40px', height: '40px'
                            }} onClick={e => setShowDeleteUserModal(userDetails)}>
                                <img src="/images/delete-icon.svg" style={{marginTop: '-3px'}} alt="delete icon"/>
                            </button>
                            <button className={users_styles.primaryBtn}
                                    onClick={() => setShowEditUserModal(userDetails)}
                                    style={{
                                        padding: '5px 30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                Edit
                            </button>
                        </div>
                    </div>
                </div>


                {!userDetails &&
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: '40px'
                    }}>
                        <Spinner animation="border"/>
                    </div>}
                {userDetails &&
                    <>
                    <div className="row" style={{paddingBottom: '20px'}}>
                        <div className="col-12" style={{display: 'flex', gap: '20%'}}>
                            <KVPair title="User's Full Name" value={userDetails.fullName}/>
                            <KVPair title="Email" value={userDetails.emailAddress}/>
                            <KVPair title="Status" color={userDetails.accountStatus==="Approved"?'#63BA3C':'#D30B0B'} value={userDetails.accountStatus==="Approved"?'Active':'Inactive'}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" style={{paddingBottom: '10px'}}>
                            <h5>Procedures</h5>
                        </div>
                    </div>
                    {userDetails.procedures.length === 0 &&
                        <div className="row" style={{display: 'flex', justifyContent: 'center', color: 'lightgray'}}>
                            No Procedures!
                        </div>}
                    {userDetails.procedures.length > 0 &&
                        userDetails.procedures.map((procedure, i) =>
                            <>
                                <div className="row">
                                    <div className="col-12">
                                        <KVPair title={`Procedure ${i+1}`}
                                                styles={{gap: '15px', alignItems: 'center'}}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <div style={{display: 'flex', gap: '10%'}}>
                                            <KVPair title="Title"
                                                    styles={{alignItems: 'center', gap: '20px'}}
                                                    value={<div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '20px',
                                                        flexDirection: 'row'
                                                    }}>
                                                        <div style={{paddingLeft: '15px'}}>{procedure.procedureTitle}</div>
                                                        <div style={{paddingTop: '5px'}}>
                                                            <button className={users_styles.warningSecondaryBtn}
                                                                    onClick={() => setShowCancelProcedure(procedure.userProcedureId)}
                                                                    style={{
                                                                        padding: '5px 30px',
                                                                        width: '220px',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                Cancel Procedure
                                                            </button>
                                                        </div>
                                                    </div>}/>
                                            <KVPair title="Date" value={moment.utc(procedure.dateTime).local().format("MM/DD/YYYY")} />
                                            <KVPair title="Time" value={moment.utc(procedure.dateTime).local().format("HH:mm")} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12" style={{paddingTop: '20px'}}>
                                        <KVPair title="Set of Steps"/>
                                    </div>
                                    <TaskList procedureTime={procedure.dateTime} procedureDate={procedure.dateTime} steps={procedure.steps}
                                              setTitle={procedure.procedureSet.procedureSetName}
                                    />
                                </div>
                                {procedure?.survey?.surveyId&&  <div className="row">
                                    <div className="col-12" style={{paddingTop: '20px'}}>
                                        <KVPair title="Surveys"/>
                                    </div>
                               <SurveyList surveys={procedure?.survey}/>
                                </div>}
                                <div className="row">
                                    <div className="col-12" style={{paddingTop: '20px'}}>
                                        <KVPair title="Tips and FAQs"/>
                                    </div>
                                    <FaqAndTipsList faqAndTips={procedure.tips}/>
                                </div>
                            </>)}
                    </>}
            </div>
        </div>
    )
}

const ViewUserPage = () => {
    return (
        <div id="wrapper">
            <Sidebar/>
            <Header/>
            <section id="content-wrapper" className={styles.content}>
                <ViewUser/>
            </section>
        </div>
    )
}

export default ViewUserPage;
