import Modal from "react-bootstrap/Modal";
import React, {useState} from "react";
import users_styles from "../users.module.scss";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import {fetchDeleteRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import Spinner from "react-bootstrap/Spinner";

const CancelProcedure = ({ open, onClose, onSuccess, userProcedureId }) => {
    const [deleting, setDeleting] = useState(false);

    const cancelProcedure = async () => {
        setDeleting(true);
        try {
            const resp = await fetchDeleteRequest(endpoints.users.cancelUserProcedure(userProcedureId));
            toastMessage(constants.TOAST_TYPE.SUCCESS, "Procedure cancelled successfully!");// resp.data.message)
            onSuccess?.();
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setDeleting(false);
        }
    }

    return (
        <Modal show={open} scrollable size="md">
            <Modal.Header style={{borderBottom: 'none', display: 'flex', alignItems: 'flex-start'}}>
                <Modal.Title style={{color: '#666666', textAlign: 'center', width: '100%', marginTop: '30px'}}>Cancel User Procedure</Modal.Title>
                <img className={users_styles.hoverable} src="/images/close-icon.png"
                     style={{width: '30px', height: '30px'}} onClick={onClose} />
            </Modal.Header>
            <Modal.Body>
                <p style={{color: '#666666', textAlign: 'center'}}>
                    Are you sure you want to cancel this Procedure?
                </p>
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'center', borderTop: 'none'}}>
                {/*<button className={procedures_styles.secondaryBtn} style={{padding: '5px 30px', width: '220px'}} onClick={onClose}>Cancel</button>*/}
                <button className={users_styles.warningBtn} style={{
                    padding: '5px 0px', width: '220px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                }} disabled={deleting} onClick={() => {
                    cancelProcedure();
                }}>
                    {deleting &&
                        <div>
                            <Spinner animation="border" style={{marginRight: '20px'}} />
                        </div>}
                    Cancel Procedure
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default CancelProcedure;
