import React, {useEffect, useState, useRef} from "react";
import styles from "./login.module.scss";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {getLoginData, setAccessToken, storeAccessTokenFromRefreshToken} from "../../store/reducers/loginData";
import constants from "../../utils/constants";
import InputErrorMessage from "../../app/components/inputErrorMessage";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";


const Login = () => {
  const loginData = useSelector((state) => state.loginData);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    emailAddress: "",
    password: "",
  });
  const [loggingIn, setLoggingIn] = useState(false);

  const validationSchema = yup.object().shape({
    emailAddress: yup
      .string()
      .email("Must be a valid email address")
      .required("Email address is required")
      .trim(),
    password: yup.string().required("Please Enter your password").trim(),
  });

  const login = async (values) => {
    setLoggingIn(true);
    const payload = {
      userType: constants.USER_TYPE.ADMINISTRATOR,
      emailAddress: values.emailAddress,
      password: values.password,
    };
    try {
      const result = await dispatch(getLoginData(payload));
      if(result?.payload?.user) {
        navigate("/");
      } else {
        setInitialValues({
          emailAddress: values.emailAddress,
          password: ""
        })
      }
    } catch(err) {
      console.error(err);
    } finally {
      setLoggingIn(false);
    }
  };

  return (
    <div className={styles.loginBody}>
      <div className={styles.container}>
        <div className={styles.loginContent}>
          <div className={styles.loginContentLeft}>
            <img src={"../../images/logo.png"} alt="Young Automotive Group" style={{ height: '200px', width: 'auto' }} />
          </div>
          <div className={styles.loginContentRight}>
            <div className={styles.loginForm}>
              <h2>Login</h2>
              <p>You are logging in as an Admin</p>
              <Formik enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  login(values);
                }}
              >
                {({ values, setFieldValue, handleSubmit, handleBlur, handleChange }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className={styles.loginFormGroup}>
                        <label>Email Address</label>
                        <input
                          type="text"
                          placeholder="email@example.com"
                          name="emailAddress"
                          onBlur={handleBlur}
                          onChange={e =>
                              setFieldValue("emailAddress", e.target.value.trim())}
                          value={values.emailAddress}
                        />
                        <InputErrorMessage name="emailAddress" />
                      </div>
                      <div className={styles.loginFormGroup}>
                        <label>Password</label>
                        <input
                          type="password"
                          placeholder="Your password"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                        />
                        <InputErrorMessage name="password" />
                      </div>
                      <div style={{
                        display: 'flex', justifyContent: 'flex-end',
                        marginTop: '20px', marginBottom: '25px',
                      }}>
                        <a href="/forgot-password" style={{
                          textDecoration: 'none', color: '#6dd2d2', fontSize: '15px'
                        }}>Forgot Password?</a>
                      </div>
                      <div className={styles.butSec}>
                        <button className={styles.signBtn} disabled={loggingIn} type="submit" style={{display: 'flex', alignItems: 'center'}}>
                          {loggingIn &&
                            <Spinner animation="border" style={{marginRight: '20px'}} />}
                          Login
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
