import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import surveys_styles from "../surveys.module.scss";
import Spinner from "react-bootstrap/Spinner";
import React, {useState} from "react";
import toastMessage from "../../../utils/toastMessage";
import {fetchPatchRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import constants from "../../../utils/constants";


const EditSurveyTitlePopup = ({ surveyData, onClose, onSave}) => {
    const [survey, setSurvey] = useState(surveyData);
    const [savingData, setSavingData] = useState(false);

    const saveSurveyTitle = async () => {
        setSavingData(true);
        try {
            const data = {title: survey.title};
            const resp = await fetchPatchRequest(endpoints.surveys.update(surveyData.surveyId), data);
            toastMessage(constants.TOAST_TYPE.SUCCESS, resp.data.message);
            onSave?.();
        } catch(err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setSavingData(false);
        }
    }


    return (
        <Modal show={true} size="lg" scrollable>
            <Modal.Header style={{borderBottom: 'none'}}>
                <Modal.Title>Edit Survey</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="edit-survey-form" onSubmit={e => {
                    e.preventDefault(); e.stopPropagation();
                    saveSurveyTitle();
                }}>
                    <Form.Group className="mb-1">
                        <Form.Label>Survey Title</Form.Label>
                        <Form.Control type="text" placeholder="Survey Title" required
                                      value={survey.title} onChange={e => setSurvey(x => ({...x, title: e.target.value}))} maxLength={65}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'center', borderTop: 'none'}}>
                <button className={surveys_styles.secondaryBtn} style={{padding: '5px 30px'}} onClick={onClose}>Cancel</button>
                <button form="edit-survey-form" type="submit" style={{
                    padding: '5px 30px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}
                        disabled={savingData}
                        className={surveys_styles.primaryBtn}>
                    {savingData &&
                        <Spinner animation="border" style={{marginRight: '20px'}} />}
                    Save
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditSurveyTitlePopup;
