import React, { useState, useEffect } from "react";
import Sidebar from "../../../app/components/sidebar";
import Header from "../../../app/components/header";
import styles from "../procedures.module.scss";
import { useParams } from "react-router-dom";
import proceduresData_sample from "../procedures-data.json";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import AddProcedureSetPopup from "../add-procedure-set-popup";
import AddStepPopup from "../add-step-popup";
import DeleteProcedureStepConfirmationPopup from "../delete-step-popup";
import { fetchGetRequest } from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import ExpandImagePopup from "../expand-image-popup";
import {
  timeStrToOrderableNumber,
  timeStrToReadableString,
} from "../../../utils/when";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import SetStatusSelector from "./SetStatusSelector";
import SetSelector from "./SetSelector";
import EditProcedureSetNamePopup from "../edit-procedure-set-name-popup";
import DeleteProcedureSetConfirmationPopup from "../delete-procedure-set-confirmation-popup";

const ProcedureStepCard = ({
  heading,
  procedureStepId,
  when,
  isBeforeProcedure,
  description,
  editModeOn,
  onEdit,
  onDelete,
  procedureStepImageUrl,
}) => {
  const [showExpandedImage, setShowExpandedImage] = useState(false);

  return (
    <div className={styles.procedureStepCard}>
      {showExpandedImage && (
        <ExpandImagePopup
          onClose={() => setShowExpandedImage(false)}
          imageURL={procedureStepImageUrl}
        />
      )}

      <div className={styles.info}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h5>{heading}</h5>
          <div style={{ display: "flex", alignItems: "center" }}>
            {editModeOn && (
              <>
                <img
                  src="/images/edit-pencil.svg"
                  className={styles.clickable}
                  style={{ height: "22px", width: "auto", marginRight: "15px" }}
                  onClick={onEdit}
                />
                <img
                  src="/images/dustbin.svg"
                  className={styles.clickable}
                  style={{ height: "22px", width: "auto" }}
                  onClick={onDelete}
                />
              </>
            )}
          </div>
        </div>
        <p style={{ marginBottom: "5px", marginTop: "5px" }}>
          {timeStrToReadableString(when, isBeforeProcedure)}
        </p>
        <small style={{ wordBreak: "break-word" }}>{description}</small>
      </div>
      <div
        className={styles.imgContainer}
        onClick={() => setShowExpandedImage(true)}
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundImage: `url(${procedureStepImageUrl})`,
        }}
      ></div>
    </div>
  );
};

const ViewProcedure = ({ procedureId }) => {
  const [procedureData, setProcedureData] = useState(null);
  const [stepsData, setStepsData] = useState([]);
  const [allSets, setAllSets] = useState(null);
  const [currentSet, setCurrentSet] = useState(null);
  const [editModeOn, setEditModeOn] = useState(false);

  const [showAddStep, setShowAddStep] = useState(false);
  const [showEditStep, setShowEditStep] = useState(null);
  const [showDeleteStep, setShowDeleteStep] = useState(null);
  const [showAddProcedureSet, setShowAddProcedureSet] = useState(false);
  const [showEditProcedureSet, setShowEditProcedureSet] = useState(null);
  const [showDeleteProcedureSet, setShowDeleteProcedureSet] = useState(null);

  const fetchProcedure = async () => {
    try {
      const resp = await fetchGetRequest(
        endpoints.procedures.getProcedure(procedureId)
      );
      if (resp.data.procedure) {
        const data = resp.data.procedure;
        setProcedureData(data);
      }
    } catch (err) {
      console.error(err);
      //toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
    }
  };

  const fetchStepsData = async (procedureStepId) => {
    setStepsData(null);
    try {
      const resp = await fetchGetRequest(
        endpoints.procedures.steps.getSteps(procedureId, procedureStepId)
      );
      if (Array.isArray(resp.data.procedureSteps)) {
        const steps = resp.data.procedureSteps.sort((a, b) => {
          const x = timeStrToOrderableNumber(a.when, a.isBeforeProcedure);
          const y = timeStrToOrderableNumber(b.when, b.isBeforeProcedure);
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
        setStepsData(steps);
        fetchProcedure()
      }
    } catch (err) {
      console.error(err);
      // toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
    }
  };

  const fetchSetsData = async (useCurrentSetId) => {
    try {
      const resp = await fetchGetRequest(
        endpoints.procedures.sets.getAll(procedureId)
      );
      if (Array.isArray(resp.data.procedureSets)) {
        const defaultSet = {
          setName: "Default",
          procedureSetId: "",
        };
        const sets = [defaultSet, ...resp.data.procedureSets];
        const setData = !useCurrentSetId
          ? undefined
          : sets.find((x) => x.procedureSetId === useCurrentSetId);
        if (setData) {
          setCurrentSet(setData);
        } else setCurrentSet(defaultSet);
        setAllSets(sets);
        fetchProcedure()
      }
    } catch (err) {
      console.error(err);
      toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
    }
  };

  const updateSetStatus = (procedureSetId, newStatus) => {
    setAllSets(x => {
        return x.map(y =>
            (y.procedureSetId === procedureSetId)
            ? ({...y, status: newStatus})
            : y
        );
    })
  }

    useEffect(() => {
        fetchProcedure();
        fetchSetsData();
        fetchStepsData();
    }, []);

  return procedureData === null || allSets === null ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Spinner animation="border" />
    </div>
  ) : (
    <>
      <div className="row">
        {showAddProcedureSet && (
          <AddProcedureSetPopup
            open={true}
            procedureId={procedureId}
            procedureName={procedureData.title}
            onClose={() => {
              setShowAddProcedureSet(null);
              fetchSetsData(currentSet.procedureSetId);
            }}
          />
        )}

        {showEditProcedureSet && (
          <EditProcedureSetNamePopup
            open={true}
            procedureId={procedureId}
            procedureSetData={showEditProcedureSet}
            onClose={() => setShowEditProcedureSet(null)}
            onSave={() => {
              setShowEditProcedureSet(null);
              fetchSetsData(showEditProcedureSet.procedureSetId);
            }}
          />
        )}

        {showDeleteProcedureSet && (
          <DeleteProcedureSetConfirmationPopup
            open={true}
            procedureSetTitle={showDeleteProcedureSet.setName}
            procedureSetId={showDeleteProcedureSet.procedureSetId}
            onDelete={() => {
              setShowDeleteProcedureSet(false);
              fetchSetsData();
              fetchStepsData();
            }}
            onClose={() => {
              setShowDeleteProcedureSet(false);
            }}
          />
        )}

        {showAddStep && (
          <AddStepPopup
            open={showAddStep}
            procedureData={procedureData}
            onClose={() => setShowAddStep(false)}
            heading={`Step ${(stepsData?.length|| 0) + 1}`}
            procedureSetId={currentSet.procedureSetId}
            onSave={() => {
              setShowAddStep(false);
              fetchStepsData(currentSet.procedureSetId);
            }}
          />
        )}
        {showEditStep && (
          <AddStepPopup
            open={!!showEditStep}
            procedureData={procedureData}
            procedureSetId={currentSet.procedureSetId}
            heading={`Step ${
              (procedureData?.steps?.findIndex(
                (x) => x.procedureStepId === showEditStep.procedureStepId
              ) || 0) + 1
            }`}
            stepData={showEditStep}
            onClose={() => setShowEditStep(false)}
            onSave={() => {
              setShowEditStep(false);
              fetchStepsData(currentSet.procedureSetId);
            }}
          />
        )}
        {showDeleteStep && (
          <DeleteProcedureStepConfirmationPopup
            open={!!showDeleteStep}
            procedureStepId={showDeleteStep.procedureStepId}
            onClose={() => setShowDeleteStep(null)}
            stepsData={stepsData}
            procedureData={procedureData}
            onDelete={() => {
              setShowDeleteStep(null);
              fetchStepsData(currentSet.procedureSetId);
              fetchProcedure()
            }}
          />
        )}
        <div
          className="col-lg-12"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex" }}>
            <Link to="/" style={{ display: "flex", alignItems: "center" }}>
              <img
                src="/images/arrowhead-left.svg"
                style={{ marginRight: "32px", marginLeft: "10px" }}
              />
            </Link>
            <h2
              className={styles.contentTitle}
              style={{ wordBreak: "break-word" }}
            >
              {procedureData.title}
            </h2>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <button
              className={styles.procedureAddSetBtn}
              onClick={() => {
                editModeOn ? setShowAddStep(true) : setShowAddProcedureSet(true);
              }}
            >
              {editModeOn ? "Add Step" : "Add Set"}
            </button>
            <SetSelector
              procedureId={procedureId}
              setsData={allSets}
              currentSet={currentSet}
              onChange={(newSetData) => {
                  setCurrentSet(newSetData);
                  fetchStepsData(newSetData.procedureSetId);
              }}
              editModeOn={editModeOn}
              onEditSet={() => {
                setShowEditProcedureSet(currentSet);
              }}
            />
           {!editModeOn&& <SetStatusSelector
              procedureSetData={currentSet ? currentSet : { status: "Active" }}
              onChange={(newSetData) => {
                  updateSetStatus(newSetData.procedureSetId, newSetData.status);
                  setCurrentSet(newSetData);
              }}
              key={currentSet.procedureSetId}
            />}
           {!editModeOn&& <img
              src="/images/procedureEditIcon.svg"
              className={styles.clickable}
              style={{ height: "40px", width: "auto" }}
              onClick={() => setEditModeOn(true)}
            />}
          </div>
        </div>
      </div>
      <div className="row">
        {stepsData?.map((procedureStep, i) => (
          <div className="col-lg-6" style={{ padding: "15px 18px" }}>
            <ProcedureStepCard
              {...procedureStep}
              heading={`Step ${i + 1}`}
              editModeOn={editModeOn}
              onEdit={() => setShowEditStep(procedureStep)}
              onDelete={() => setShowDeleteStep(procedureStep)}
            />
          </div>
        ))}

        {stepsData === null && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spinner animation="border" />
          </div>
        )}

        {stepsData && !stepsData.length && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "grey",
              paddingTop: "30px",
            }}
          >
            No records!
          </div>
        )}
      </div>
     {procedureData === null || allSets === null||stepsData === null ?<></> :editModeOn?<div
          style={{
             display:'flex',
             justifyContent:'center',
             alignItems:'center',
             marginTop:'5%'
          }}
        >
       {currentSet?.procedureSetId &&
         <img
            src="/images/deleteProcedureIcon.svg"
            className={styles.clickable}
            style={{ height: "40px", width: "auto",marginRight:'3%' }}
            onClick={() => setShowDeleteProcedureSet(currentSet)}
          />}
          <img
            src="/images/procedureDoneIcon.svg"
            className={styles.clickable}
            style={{ height: "40px", width: "auto" }}
            onClick={() => setEditModeOn(false)}
          />
        </div>:<></>}
    </>
  );
};

const ViewProcedurePage = () => {
  const { procedureId } = useParams();
  return (
    <div id="wrapper" className="">
      <Sidebar />
      <Header />
      <section id="content-wrapper">
        <ViewProcedure procedureId={procedureId} />
      </section>
    </div>
  );
};

export default ViewProcedurePage;
