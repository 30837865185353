import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import Card from "react-bootstrap/Card";
import { useState, useContext } from "react";
import users_styles from "../users.module.scss";
import moment from "moment";
import RangeSlider from "react-bootstrap-range-slider";

const CustomToggle = ({ survey, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey, () => {});
  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "20px",
        padding: "7px 9px",
      }}
      onClick={decoratedOnClick}
      className={users_styles.hoverable}
    >
      <h6 style={{ color: "#000000", marginBottom: 0 }}>
        {survey?.surveyTitle}
      </h6>
      <p style={{ color: "#AAAAAA", marginBottom: 0 }}>
        {survey?.surveyCompletedAt
          ? `Completed on ${moment.utc(survey?.surveyCompletedAt).local().format(
              "MM/DD/YYYY"
            )}`
          : `Assigned at ${moment.utc(survey?.surveyAssignedAt).local().format(
              "MM/DD/YYYY"
            )}`}
      </p>
      <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        <img
          src={
            isCurrentEventKey
              ? "/images/arrowhead-up.svg"
              : "/images/arrowhead-down.svg"
          }
        />
      </div>
    </div>
  );
};

const Answer_Slider = ({ question }) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ color: "#B9B9B9", fontSize: "15px", fontWeight: "normal" }}>
        Answer:
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: "10px 10px",
        }}
      >
        <RangeSlider
          step={1}
          max={2}
          value={
            question?.givenAnswers
              ? question?.givenAnswers[0] === question?.options[0]
                ? 0
                : 2
              : 1
          }
          tooltip="off"
          variant="primary"
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ color: "#B9B9B9", fontSize: "14px", fontWeight: "normal" }}
          >
            {question?.options[0]}
          </div>
          <div
            style={{ color: "#B9B9B9", fontSize: "14px", fontWeight: "normal" }}
          >
            {question?.options[1]}
          </div>
        </div>
      </div>
    </div>
  );
};

const Answer = ({ question }) => {
  return (
    <div
      style={{
        color: "#B9B9B9",
        fontSize: "15px",
        fontWeight: "normal",
        lineHeight: 1.3,
      }}
    >
      Answer: {question?.givenAnswers ? question?.givenAnswers?.join(", ") : ""}
    </div>
  );
};

const SurveyList = ({ surveys }) => {
  return (
    <>
      <Accordion defaultActiveKey="">
        <Card
          style={{
            backgroundColor: "#F7F7F7",
            border: "none",
            boxShadow: "0px 4px 4px 0px #B2B2B260",
            marginTop: "10px",
            marginBottom: "10px",
          }}
          key={surveys.surveyId}
        >
          <Card.Header style={{ border: "none" }}>
            <CustomToggle survey={surveys} eventKey={surveys.surveyId} />
          </Card.Header>
          <Accordion.Collapse
            eventKey={surveys.surveyId}
            style={{ borderTop: "1px solid #CBCBCB" }}
          >
            <Card.Body>
              {surveys?.questions?.map((question, i) => (
                <div
                  key={question.question_id}
                  style={{
                    marginTop: i == 0 ? 0 : "12px",
                    marginBottom: "12px",
                  }}
                >
                  <div style={{ fontSize: "15px", lineHeight: 1.3 }}>
                    {i + 1}. {question?.question} | {question?.questionType}
                  </div>
                  {question?.questionType === "slider" ? (
                    <Answer_Slider question={question} />
                  ) : (
                    <Answer question={question} />
                  )}
                </div>
              ))}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

export default SurveyList;
