
import Form from 'react-bootstrap/Form';
import {useEffect, useState} from "react";
import styles from "./setStatusSelector.scss";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import {fetchPatchRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import Spinner from "react-bootstrap/Spinner";

const SetStatusSelector = ({
  procedureSetData, onChange, procedureId
}) => {
    const [status, setStatus] = useState(procedureSetData.status);
    const [saving, setSaving] = useState(false);

    const getStylesByStatus = (status) => {
        if(status === 'Active') {
            return {
                appearance: 'none', backgroundColor: 'white', color: 'black', // backgroundColor: '#4DCDCD'
                backgroundImage: 'url("/images/black-dropdown-arrow.svg")', backgroundSize: '10px 10px'
            };
        } else if(status === 'Deactivated') {
            return {
                appearance: 'none', backgroundColor: 'white', color: 'black', // backgroundColor: '#013399'
                backgroundImage: 'url("/images/black-dropdown-arrow.svg")', backgroundSize: '10px 10px'
            }
        } else if(status === 'Paused') {
            return {
                appearance: 'none', backgroundColor: 'white', color: 'black', // backgroundColor: '#ECFAFE', color: '#013399'
                backgroundImage: 'url("/images/black-dropdown-arrow.svg")', backgroundSize: '10px 10px'
            }
        }
        return {}
    }

    const updateStatus = async (newStatus) => {
        setSaving(true);
        try {
            const resp = await fetchPatchRequest(endpoints.procedures.sets.updateStatus(procedureSetData.procedureSetId), {
                status: newStatus,
            })
            onChange(({
                ...procedureSetData, status: newStatus
            }))
            setStatus(newStatus);
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setSaving(false);
        }
    }

    useEffect(() => {
        setStatus(procedureSetData.status);
    }, [procedureSetData]);

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {saving
                ?
                <Spinner animation="border" />
                :
                <Form.Select style={{
                    width: 'auto', borderRadius: '100px', padding: '5px 10px', minWidth: '150px',
                    ...getStylesByStatus(status)
                }} value={status} className={styles.procedureStatusSelector}
                             onChange={e => {
                                 updateStatus(e.target.value)
                             }}>
                    <option value="Active">Active</option>
                    {procedureSetData.procedureSetId &&
                        <>
                            <option value="Deactivated">Deactivated</option>
                            <option value="Paused">Paused</option>
                        </>}
                </Form.Select>}
        </div>
    )
}



export default SetStatusSelector;

