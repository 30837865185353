import React, {useState} from "react";
import {fetchDeleteRequest, fetchPostRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import DeleteProcedureConfirmationPopup from "../delete-procedure-confirmation-popup";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import procedures_styles from "../procedures.module.scss";
import Spinner from "react-bootstrap/Spinner";
import {RenderProcedureStepForm} from "../add-procedure-popup";


const AddProcedureSetPopup = ({procedureId, procedureName, open, onClose}) => {
    const [step, setStep] = useState(0);
    const [savingData, setSavingData] = useState(false);
    const [canceling, setCanceling] = useState(false);
    const [procedureSet, setProcedureSet] = useState({
        status: 'Active', setName: '', steps: []
    });

    const addSet = async () => {
        setSavingData(true);
        try {
            const resp = await fetchPostRequest(endpoints.procedures.sets.create, {
                'status': procedureSet.status, 'setName': procedureSet.setName, procedureId
            });
            setProcedureSet(x => ({
                ...x, setName: procedureSet.setName, status: procedureSet.status, procedureSetId: resp.data.procedureSetId
            }))
            appendStep();
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setSavingData(false);
        }
    }

    const deleteSet = async () => {
        setCanceling(true);
        try {
            const resp = await fetchDeleteRequest(endpoints.procedures.sets.delete(procedureSet.procedureSetId))
            if(resp.status < 400) {
                // toastMessage(constants.TOAST_TYPE.SUCCESS, resp.data.message);
            }
            onClose?.();
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setCanceling(false);
        }
    }

    const appendStep = () => {
        setProcedureSet(x => {
            const y = {...x};
            y.steps.push({when: '01:00:00', isBeforeProcedure: false, description: '', procedureStepImage: null})
            return y;
        })
        setStep(x => x + 1);
    }

    const addStep = async (onSuccess) => {
        setSavingData(true);
        const lastStep = procedureSet.steps[procedureSet.steps.length - 1];
        const payload = new FormData();
        payload.append("procedureId", procedureId);
        payload.append("when", lastStep.when);
        payload.append("isBeforeProcedure", lastStep.isBeforeProcedure);
        payload.append("description", lastStep.description);
        payload.append("procedureStepImage", lastStep.procedureStepImage);
        payload.append("procedureSetId", procedureSet.procedureSetId);
        try {
            const resp = await fetchPostRequest(endpoints.procedures.steps.create, payload);
            if(resp.status < 400) {
                toastMessage(constants.TOAST_TYPE.SUCCESS, resp.data.message);
                appendStep();
            }
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setSavingData(false);
        }
    }

    const setStepData = (x) => {
        if(typeof x === 'function') {
            setProcedureSet(y => {
                const z = {...y};
                z.steps[z.steps.length - 1] = x(z.steps[z.steps.length - 1]);
                return z;
            })
        } else {
            setProcedureSet(y => {
                const z = {...y};
                z.steps[z.steps.length - 1] = x;
                return z;
            })
        }
    }

    return (
        <>
            <Modal show={open} size="lg" scrollable>
                <Modal.Header style={{borderBottom: 'none'}}>
                    <Modal.Title>Add Another Set of Steps to {procedureName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="add-procedure-form" onSubmit={e => {
                        e.preventDefault(); e.stopPropagation();
                        if(step === 0) {
                            addSet();
                        } else {
                            addStep()
                        }
                    }}>
                        <Form.Group className="mb-1">
                            <Form.Label>Set Title</Form.Label>
                            <Form.Control type="text" placeholder="Set Title" readOnly={step > 0} required={step === 0}
                                  value={procedureSet.title} onChange={e => setProcedureSet(x => ({...x, setName: e.target.value}))}
                            />
                        </Form.Group>
                        <br />
                        {step > 0 &&
                            <RenderProcedureStepForm isAdd={true} key={step} heading={`Step ${step}`}
                                 stepData={procedureSet.steps[procedureSet.steps.length - 1]} setStepData={setStepData}
                            />}
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{display: 'flex', justifyContent: 'center', borderTop: 'none'}}>
                    {step > 0 &&
                        <>
                            <button className={procedures_styles.secondaryBtn} onClick={() => deleteSet()}
                                    disabled={savingData || canceling} style={{
                                padding: '5px 30px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                            }}>
                                {canceling &&
                                    <Spinner animation="border" style={{marginRight: '20px'}}/>}
                                Cancel
                            </button>
                            <button className={procedures_styles.primaryBtn} style={{
                                padding: '5px 30px', background: '#4DCDCD', borderColor: '#4DCDCD',
                                display: 'flex', alignItems: 'center', justifyContent: 'center'
                            }} form="add-procedure-form" disabled={savingData}>
                                {savingData &&
                                    <Spinner animation="border" style={{marginRight: '20px'}}/>}
                                Add Step
                            </button>
                            {step > 1 &&
                                <button className={procedures_styles.primaryBtn} style={{padding: '5px 30px'}}
                                        onClick={onClose}>Done
                                </button>}
                        </>}
                    {step === 0 &&
                        <>
                            <button className={procedures_styles.secondaryBtn} style={{padding: '5px 30px'}}
                                    onClick={onClose}>Cancel</button>
                            <button form="add-procedure-form" type="submit" style={{
                                padding: '5px 30px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                            }}
                                    disabled={savingData || canceling}
                                    className={procedures_styles.primaryBtn}>
                                {savingData &&
                                    <Spinner animation="border" style={{marginRight: '20px'}}/>}
                                Next
                            </button>
                        </>}
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default AddProcedureSetPopup;
