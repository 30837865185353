import Sidebar from "../../app/components/sidebar";
import Header from "../../app/components/header";
import React, {useEffect, useState} from "react";
import styles from "./faqs-and-tips.module.scss";
import {Link} from "react-router-dom";
import proceduresData_sample from "../procedures/procedures-data.json";
import Spinner from "react-bootstrap/Spinner";
import {fetchGetRequest} from "../../utils/network";
import endpoints from "../../utils/endpoints";
import toastMessage from "../../utils/toastMessage";
import constants from "../../utils/constants";
import InfiniteScroll from 'react-infinite-scroller';


const FaqCard = ({procedureData}) => {
    const {procedureId, title} = procedureData;
    return (
        <Link className={styles.faqCard} to={`/faqs-and-tips/${procedureId}`}>
            <div className={styles.info}>
                <h5 style={{marginBottom: '5px', wordBreak: 'break-word'}}>{title}</h5>
            </div>
        </Link>
    )
}


const FaqsAndTips = () => {
    const [proceduresData, setProceduresData] = useState(null);

    const [pagination, setPagination] = useState({page: 1, size: 20});
    const [lastPage, setLastPage] = useState(1);

    const [loadingMore, setLoadingMore] = useState(false);

    const fetchProcedures = async () => {
        setLoadingMore(true);
        try {
            const resp = await fetchGetRequest(endpoints.procedures.getAllProcedures, {params: pagination})
            if(Array.isArray(resp.data.procedures)) {
                if(resp.data.pagination.lastPage) setLastPage(resp.data.pagination.lastPage);

                setProceduresData(x => {
                    const y = [...(x || [])];
                    for(let dt of resp.data.procedures) {
                        if(!y.some(z => z.procedureId === dt.procedureId)) y.push(dt);
                    }
                    y.sort((a, b) => {
                        const a_ = a.title.toLowerCase();
                        const b_ = b.title.toLowerCase();
                        if (a_ < b_)
                            return -1;
                        if (a_ > b_)
                            return 1;
                        return 0;
                    });
                    console.log("new_y: ", y);
                    return y;
                });
            }
        } catch(err) {
            console.error(err);
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        } finally {
            setLoadingMore(false);
        }
    }

    const switchPage = newPage => {
        console.log("switchPage: ", newPage);
        setPagination(x => ({
            ...x, page: newPage
        }))
    }

    const refresh = () => {
        setProceduresData(null);
        setLastPage(1);
        setPagination({page: 1, size: 3});
        fetchProcedures();
    }

    useEffect(() => {
        fetchProcedures();
    }, [pagination]);

    return (
        proceduresData === null
            ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner animation="border" />
            </div>
            :
            <InfiniteScroll
                pageStart={1}
                loadMore={switchPage}
                hasMore={pagination.page < lastPage}
                loader={null}
                useWindow={true}
            >
                <div className="row">
                    <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className={styles.contentTitle} style={{marginBottom: '20px'}}>Manage FAQs and Tips</h2>
                    </div>
                </div>

                <div className="row">
                    {proceduresData.map(procedureItem =>
                        <div className="col-lg-6" style={{ padding: '8px 8px' }}>
                            <FaqCard procedureData={procedureItem} />
                        </div>)}
                </div>

                {proceduresData?.length === 0 &&
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'grey', paddingTop: '30px'}}>
                        No records!
                    </div>}

                {loadingMore &&
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Spinner animation="border"/>
                    </div>}

            </InfiniteScroll>
    )
}


const FaqsAndTipsPage = () => {
    return (
        <div id="wrapper" className="">
            <Sidebar/>
            <Header />
            <section id="content-wrapper">
                <FaqsAndTips />
            </section>
        </div>
    )
}

export default FaqsAndTipsPage;
