import styles from "../surveys.module.scss";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {getFormattedDate} from "../../../utils";
import Table from "react-bootstrap/Table";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import {fetchDeleteRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";

const AssignedToUsers = ({
    surveyId, assignedUsers, onRefresh
}) => {
    const [unassigning, setUnassigning] = useState({});
    const navigate = useNavigate();
    const statusImageMapping = {
        "Pending": "/images/survey_pending_status.svg",
        "Completed": "/images/survey_complete_status.svg",
        "Rejected": "/images/survey_rejected_status.svg",
    }

    const unassignUser = async (userProcedureId) => {
        setUnassigning(x => ({
            ...x, [userProcedureId]: true
        }))
        try {
            const resp = await fetchDeleteRequest(endpoints.surveys.unassignUser(userProcedureId));
            if(resp.status < 400) {
                // Success
                onRefresh?.();
            }
        } catch(err) {
            console.error(err);
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        } finally {
            setUnassigning(x => {
                const y = {...x};
                delete y?.[userProcedureId];
                return y;
            })
        }
    }

    return (
        <div>
            <div className="row" style={{paddingBottom: '20px', padddingTop: '10px'}}>
                <div className="col-lg-12" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <h5>Assigned To:</h5>
                    <div>
                        <button className={styles.primaryBtn} style={{padding: '8px 50px', fontWeight: 'normal', width: '250px'}}
                                onClick={() => navigate(`/survey/${surveyId}/assign-users`)}>Assign To Users
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <Table responsive hover className={styles.usersListing}>
                    <thead>
                    <tr>
                        <th>User's Full Name</th>
                        <th>Procedure</th>
                        <th>Set</th>
                        <th>Date</th>
                        <th>Survey Sent</th>
                        <th style={{width: '20%'}}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {assignedUsers?.map(({user, procedure, procedureDateTime, procedureSet, surveyStatus, userProcedureId, surveyAssignTime, createdAt}) =>
                        <tr key={user.id} className={styles.hoverable}
                            onClick={() => navigate(`/users/${user.userId}`)}>
                            <td style={{verticalAlign: 'middle'}}>{user.fullName}</td>
                            <td style={{verticalAlign: 'middle'}}>{procedure?.procedureName || "N/A"}</td>
                            <td style={{verticalAlign: 'middle'}}>{procedureSet?.procedureSetName || "N/A"}</td>
                            <td style={{verticalAlign: 'middle'}}>{moment.utc(procedureDateTime).local().format("MM/DD/YYYY") || "N/A"}</td>
                            <td style={{verticalAlign: 'middle'}}>{moment.utc(surveyAssignTime).local().format("MM/DD/YYYY")|| "N/A"}</td>
                            <td>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    gap: '15px'
                                }}>
                                    {surveyStatus === "Pending" &&
                                        <button className={styles.primaryBtn}
                                                style={{
                                                    padding: '6px 5px',
                                                    fontSize: '15px',
                                                    fontWeight: 'normal',
                                                    width: '200px',
                                                    height: '35px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                                onClick={e => {
                                                    e.preventDefault(); e.stopPropagation();
                                                    unassignUser(userProcedureId)
                                                }}>
                                            {unassigning?.[userProcedureId] &&
                                                <Spinner size="xs" animation="border" style={{marginRight: '20px'}}/>}
                                            Unassign
                                        </button>}
                                    <img src={statusImageMapping[surveyStatus]}/>
                                    <button style={{background: 'transparent', border: 'none'}}>
                                        <img src="/images/arrowhead-right.svg" alt="expand"/>
                                    </button>
                                </div>
                            </td>
                        </tr>)}
                    </tbody>
                </Table>

                {(!assignedUsers || assignedUsers?.length === 0) &&
                    <div style={{
                        display: 'flex', width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'grey',
                        paddingTop: '30px'
                    }}>
                        No records!
                    </div>}
            </div>
        </div>
    );
}


export default AssignedToUsers;
