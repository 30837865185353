import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import React, { useEffect, useState } from "react";
import DeleteUser from "../delete-user";
import users_styles from "../users.module.scss";
import Form from "react-bootstrap/Form";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import { fetchGetRequest, fetchPatchRequest } from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import data from "bootstrap/js/src/dom/data";
import { getDateStringIn_YYYY_MM_DD } from "../../../utils";
import moment from "moment";

const EditUser = ({ open, onClose, userData, onSuccess }) => {
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(null);
  const [savingData, setSavingData] = useState(false);
  const [procedures, setProcedures] = useState(null);
  const [surveys, setSurveys] = useState(null);
  const [selectedProcedure, setSelectedProcedure] = useState("");
  const [selectedUserProcedure, setSelectedUserProcedure] = useState("");
  const [selectedProcedureSet, setSelectedProcedureSet] = useState("");
  const [selectedSurvey, setSelectedSurvey] = useState("");
  /*
        timeFocus is required to show placeholder text according to design in figma,
        there is no other was to do it in html, unless we make a custom component
    */
  const [timeFocus, setTimeFocus] = useState(false);
  const [procedureSets, setProcedureSets] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [fetched, setFetched] = useState(false);
  const [fetchingSets, setFetchingSets] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const saveUser = async () => {
    setSavingData(true);
    try {
      const data = {
        firstName,
        lastName,
        emailAddress: email,
        accountStatus: userStatus==='Active'?"Approved":"Suspended",
      };
      const resp = await fetchPatchRequest(
        endpoints.users.editUser(userData.userId),
        data
      );
      if (!selectedUserProcedure) {
        toastMessage(constants.TOAST_TYPE.SUCCESS, resp.data.message);
        onSuccess?.();
        return;
      }
      const data1 = {
        dateTime:moment(date).utc(),
        procedureId: selectedProcedure,
        surveyId :selectedSurvey?selectedSurvey:null,
        procedureSetId:selectedProcedureSet?selectedProcedureSet:"Default",
      };
      const resp1 = await fetchPatchRequest(
        endpoints.users.editUserProcedure(selectedUserProcedure),
        data1
      );
      toastMessage(constants.TOAST_TYPE.SUCCESS, resp.data.message);
      onSuccess?.();
    } catch (err) {
      console.error(err);
      toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
    } finally {
      setSavingData(false);
    }
  };

  const fetchUserData = async () => {
    try {
      const resp = await fetchGetRequest(
        endpoints.users.getUser(userData.userId)
      );
      if (resp.data.user) {
        setFirstName(resp.data.user.firstName);
        setLastName(resp.data.user.lastName);
        setEmail(resp.data.user.emailAddress);
        setUserStatus(resp.data.user.accountStatus==='Approved'?'Active':'Inactive');
        const procs = resp.data.user.procedures;
        if (procs.length > 0) {
          setSelectedProcedure(procs[0].procedureId);
          setSelectedProcedureSet(procs[0].procedureSet.procedureSetId);
          setSelectedSurvey(procs[0].survey.surveyId);
          setSelectedUserProcedure(procs[0].userProcedureId);
          setDate(moment.utc(procs[0].dateTime).local().format("YYYY-MM-DDTHH:mm"));
          // setTime(procs[0].time.split(":").slice(0, 2).join(":"));
        }
      }
    } catch (err) {
      console.error(err);
      toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
    }
  };

  const fetchProceduresData = async () => {
    try {
      const resp = await fetchGetRequest(endpoints.procedures.getAllProcedures);
      if (Array.isArray(resp.data.procedures)) {
        setProcedures(resp.data.procedures);
      }
    } catch (err) {
      console.error(err);
      toastMessage(constants.TOAST_TYPE.ERROR, err.data.message);
    }
  };

  const fetchSetsData = async () => {
    if (!selectedProcedure) return;
    setFetchingSets(true);
    setProcedureSets(null);
    try {
      const resp = await fetchGetRequest(
        endpoints.procedures.sets.getAll(selectedProcedure)
      );
      if (Array.isArray(resp.data.procedureSets)) {
        const defaultSet = {
          setName: "Default",
          procedureSetId: "",
          status: "Active",
        };
        const sets = [defaultSet, ...resp.data.procedureSets];
        setProcedureSets(sets);
      }
    } catch (err) {
      console.error(err);
      toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
    } finally {
      setFetchingSets(false);
    }
  };

  const fetchSurveysData = async () => {
    try {
      const params = { page: 1, size: 999 };
      const resp = await fetchGetRequest(endpoints.surveys.getAll, { params });
      if (Array.isArray(resp.data.surveys)) {
        setSurveys(resp.data.surveys);
      }
    } catch (err) {
      console.error(err);
      toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
    } finally {
    }
  };

  // Get the current time formatted as HH:MM
  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const getTodayDate = () => {
    const today = new Date();
    return getDateStringIn_YYYY_MM_DD(today);
  };

  useEffect(() => {
    setShowDeleteUserModal(null);
    Promise.all([
      fetchUserData(),
      fetchProceduresData(),
      fetchSurveysData(),
    ]).then(() => setFetched(true));
  }, []);

  useEffect(() => {
    fetchSetsData();
  }, [selectedProcedure]);

  return (
    <>
      {showDeleteUserModal && (
        <DeleteUser
          open={!!showDeleteUserModal}
          userId={userData.userId}
          onClose={() => setShowDeleteUserModal(null)}
          onDelete={() => {
            setShowDeleteUserModal(null);
            onSuccess?.();
          }}
        />
      )}

      <Modal show={!showDeleteUserModal && open} scrollable size="lg">
        <Modal.Header
          style={{
            borderBottom: "none",
            display: "flex",
            alignItems: "flex-start",
            marginTop: "20px",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          <Modal.Title
            style={{
              color: "#363636",
              textAlign: "left",
              width: "100%",
              fontWeight: "bold",
            }}
          >
            Edit User
          </Modal.Title>
          <img
            className={users_styles.hoverable}
            src="/images/close-icon.png"
            style={{ width: "30px", height: "30px" }}
            onClick={onClose}
          />
        </Modal.Header>
        <Modal.Body style={{ marginRight: "15px", marginLeft: "15px" }}>
          {!fetched && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <Spinner animation="border" />
            </div>
          )}
          {fetched && (
            <Form
              id="edit-user"
              className={users_styles.editUserForm}
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                saveUser();
              }}
            >
              <div className="row">
                <div className="col-6">
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="example@email.com"
                      value={email}
                      readOnly
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      value={userStatus}
                      onChange={(e) => setUserStatus(e.target.value)}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Procedure</Form.Label>
                    <Form.Select
                      value={selectedProcedure}
                      disabled={!selectedUserProcedure}
                      // required={selectedUserProcedure} disabled={!selectedUserProcedure}
                      onChange={(e) => {
                        setSelectedProcedure(e.target.value);
                        setSelectedProcedureSet("");
                      }}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {procedures.map((procedure) => (
                        <option
                          value={procedure.procedureId}
                          disabled={procedure.status !== "Active"}
                        >
                          {procedure.title}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Start Date & Time</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      placeholder="mm/dd/yyyy"
                      value={date}
                      required={selectedUserProcedure}
                      min={getTodayDate()}
                      disabled={!selectedUserProcedure}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Task List</Form.Label>
                    {fetchingSets ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "20px",
                        }}
                      >
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      <Form.Select
                        value={selectedProcedureSet}
                        disabled={!selectedProcedure}
                        onChange={(e) =>
                          setSelectedProcedureSet(e.target.value)
                        }
                      >
                        {/*{selectedProcedure &&*/}
                        {/*    <option value="">Default</option>}*/}
                        {!selectedProcedure && (
                          <option value="" disabled>
                            Select
                          </option>
                        )}
                        {procedureSets?.map((procedureSet) => (
                          <option
                            disabled={procedureSet.status !== "Active"}
                            value={procedureSet.procedureSetId}
                          >
                            {procedureSet.setName}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Assigned Survey</Form.Label>
                    <Form.Select
                      value={selectedSurvey}
                      disabled={!selectedUserProcedure}
                      onChange={(e) => setSelectedSurvey(e.target.value)}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {surveys.map((survey) => (
                        <option
                          value={survey.surveyId}
                          disabled={survey.status !== "Active"}
                        >
                          {survey.title}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  {/* <Form.Group className="mb-3">
                    <Form.Label>Start Time</Form.Label>
                    <Form.Control
                      type={timeFocus || time ? "time" : "text"}
                      placeholder="HH:MM [AM/PM]"
                      value={time}
                      onFocus={() => setTimeFocus(true)}
                      onBlur={() => setTimeFocus(false)}
                      min={
                        getTodayDate() === date ? getCurrentTime() : undefined
                      }
                      required={selectedUserProcedure}
                      disabled={!selectedUserProcedure}
                      onChange={(e) => setTime(e.target.value)}
                    />
                  </Form.Group> */}
                </div>
              </div>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{ borderTop: "none", marginRight: "15px", marginLeft: "15px" }}
        >
          {fetched && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "15px",
              }}
            >
              {/*<button className={users_styles.warningSecondaryBtn}*/}
              {/*        style={{*/}
              {/*            padding: '5px 30px', width: '220px',*/}
              {/*            display: 'flex',*/}
              {/*            alignItems: 'center',*/}
              {/*            justifyContent: 'center'*/}
              {/*        }}>*/}
              {/*    Cancel Procedure*/}
              {/*</button>*/}
              <button
                style={{
                  background: "#D30B0B",
                  borderRadius: "100px",
                  border: "none",
                  width: "40px",
                  height: "40px",
                }}
                onClick={(e) => setShowDeleteUserModal(userData)}
              >
                <img
                  src="/images/delete-icon.svg"
                  style={{ marginTop: "-3px" }}
                  alt="delete icon"
                />
              </button>
              <button
                className={users_styles.primaryBtn}
                type="submit"
                form="edit-user"
                style={{
                  padding: "5px 30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {savingData && (
                  <Spinner animation="border" style={{ marginRight: "20px" }} />
                )}
                Done
              </button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditUser;
