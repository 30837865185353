import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import procedures_styles from "../procedures.module.scss";
import Spinner from "react-bootstrap/Spinner";
import React, {useState} from "react";
import {RenderProcedureStepForm} from "../add-procedure-popup";
import toastMessage from "../../../utils/toastMessage";
import {fetchPatchRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import constants from "../../../utils/constants";


const EditProcedureNamePopup = ({ procedureData, open, onClose, onSave}) => {
    const [procedure, setProcedure] = useState(procedureData);
    const [savingData, setSavingData] = useState(false);

    const saveProcedureName = async () => {
        setSavingData(true);
        try {
            const data = {title: procedure.title, status: procedure.status};
            const resp = await fetchPatchRequest(endpoints.procedures.update(procedure.procedureId), data);
            onSave?.();
            toastMessage(constants.TOAST_TYPE.SUCCESS, resp.data.message);
        } catch(err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setSavingData(false);
        }
    }

    return (
        <Modal show={open} size="lg" scrollable>
            <Modal.Header style={{borderBottom: 'none'}}>
                <Modal.Title>Edit Procedure</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="add-procedure-form" onSubmit={e => {
                    e.preventDefault(); e.stopPropagation();
                    saveProcedureName();
                }}>
                    <Form.Group className="mb-1">
                        <Form.Label>Procedure Title</Form.Label>
                        <Form.Control type="text" placeholder="Procedure Title" required
                              value={procedure.title} onChange={e => setProcedure(x => ({...x, title: e.target.value}))}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'center', borderTop: 'none'}}>
                <button className={procedures_styles.secondaryBtn} style={{padding: '5px 30px'}} onClick={onClose}>Cancel</button>
                <button form="add-procedure-form" type="submit" style={{
                    padding: '5px 30px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}
                        disabled={savingData}
                        className={procedures_styles.primaryBtn}>
                    {savingData &&
                        <Spinner animation="border" style={{marginRight: '20px'}} />}
                    Save
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditProcedureNamePopup;
