import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Card from 'react-bootstrap/Card';
import {useState, useContext} from "react";
import users_styles from "../users.module.scss";
import {timeStrToOrderableNumber, timeStrToReadableString} from "../../../utils/when";
import {getFormattedDate} from '../../../utils';

const CustomToggle = ({tasks, title, eventKey}) => {
    const totalTasks = tasks.length;
    const completedTasks = tasks.reduce((acc, task) => task.viewedOn ? acc + 1 : acc, 0);

    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey, () => {});
    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '20px', padding: '7px 9px'}}
             onClick={decoratedOnClick} className={users_styles.hoverable}>
            <h6 style={{color: '#000000', marginBottom:0}}>{title}</h6>
            <p style={{color: '#AAAAAA', marginBottom:0}}>{completedTasks}/{totalTasks} tasks complete</p>
            <div style={{flex:1, display: 'flex', justifyContent: 'flex-end'}}>
                <img src={isCurrentEventKey ? "/images/arrowhead-up.svg" : "/images/arrowhead-down.svg"} />
            </div>
        </div>
    )
}


const TaskList = ({
    procedureDate, procedureTime, steps, setTitle
}) => {

    const getStepDate = (when, isBeforeProcedure) => {
        const millis = timeStrToOrderableNumber(when, isBeforeProcedure) * 1000;
        const formattedDate = new Date(procedureDate);
        const procedureDateTime = formattedDate.getTime() + timeStrToOrderableNumber(procedureTime);
        const stepDate = new Date(procedureDateTime + millis);
        let DD = stepDate.getDate() + '';
        if(DD.length == 1) DD = '0' + DD;
        let MM = (stepDate.getMonth() + 1) + '';
        if(MM.length == 1) MM = '0' + MM;
        let YYYY = stepDate.getFullYear() + '';
        return `${MM}/${DD}/${YYYY}`;
    }

    return (
        <>
            {[steps].map((step, i) =>
                <Accordion defaultActiveKey="">
                    <Card style={{
                            backgroundColor: '#F7F7F7', border: 'none', boxShadow: '0px 4px 4px 0px #B2B2B260',
                            marginTop: '10px', marginBottom: '10px'
                        }} key={i}>
                        <Card.Header style={{border: 'none'}}>
                            <CustomToggle tasks={steps} title={setTitle} eventKey={i} />
                        </Card.Header>
                        <Accordion.Collapse eventKey={i} style={{borderTop: '1px solid #CBCBCB'}}>
                            <Card.Body>
                                <div className="row">
                                    {steps.map((step, j) => (
                                        <div className="col-6" style={{padding: '20px'}}>
                                            <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between'}}>
                                                <h6 style={{marginBottom: 0, fontWeight: 'bold'}}>Step {j+1}</h6>
                                                {step?.viewedOn &&
                                                    <img src="/images/stepdone-icon.svg" alt="task done icon" />}
                                            </div>
                                            <div style={{color: '#B9B9B9', paddingBottom:'7px'}}>{timeStrToReadableString(step.when, step.isBeforeProcedure)} - {getStepDate(step.when, step.isBeforeProcedure)}</div>
                                            <div style={{color: '#B9B9B9'}}>
                                                {step.description}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>)}
        </>
    )
}


export default TaskList;