import "../../assets/styles/dashboard.css";
import Sidebar from "../../app/components/sidebar";
import Header from "../../app/components/header";
import React, {useEffect, useState} from "react";
import styles from "./procedures.module.scss";
import {Link} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import AddProcedurePopup from "./add-procedure-popup";
import DeleteProcedureConfirmationPopup from "./delete-procedure-confirmation-popup";
import {fetchGetRequest} from "../../utils/network";
import endpoints from "../../utils/endpoints";
import toastMessage from "../../utils/toastMessage";
import constants from "../../utils/constants";
import EditProcedureNamePopup from "./edit-procedure-name-popup";
import ProcedureStatusSelector from "../../components/common/ProcedureStatusSelector";
import InfiniteScroll from 'react-infinite-scroller';


const ProcedureItem = ({data, onDelete, onEdit, onChange}) => {
    const {procedureId, title, steps, numberOfDefaultSteps, status} = data;
    return (
        <div className={styles.procedureCard}>
            <Link className={styles.info} to={`/procedure/${procedureId}`} style={{flex:1}}>
                <h5 style={{marginBottom: '5px', wordBreak: 'break-word'}}>{title}</h5>
                <p>{numberOfDefaultSteps || 0} {numberOfDefaultSteps === 1 ? "step" : "steps"}</p>
            </Link>
            <ProcedureStatusSelector procedureData={data} onChange={onChange} />
            <div className={styles.clickable} onClick={onEdit} style={{
                display: 'flex', alignItems: 'center', marginLeft: '15px', marginRight: '15px'
            }}>
                <img src="/images/edit-pencil.svg"/>
            </div>
            <div className={styles.clickable} onClick={onDelete} style={{
                display: 'flex', alignItems: "center"
            }}>
                <img src="/images/dustbin.svg" />
            </div>
        </div>
    )
}

const Procedures = ({}) => {
    const [proceduresData, setProceduresData] = useState(null);
    const [showAddProcedure, setShowAddProcedure] = useState(false);
    const [showEditProcedure, setShowEditProcedure] = useState(null);
    const [showDeleteProcedure, setShowDeleteProcedure] = useState('');

    const [pagination, setPagination] = useState({page: 1, size: 20});
    const [lastPage, setLastPage] = useState(1);

    const [loadingMore, setLoadingMore] = useState(false);

    const fetchProcedures = async () => {
        setLoadingMore(true);
        try {
            const resp = await fetchGetRequest(endpoints.procedures.getAllProcedures, {params: pagination})
            if(Array.isArray(resp.data.procedures)) {
                if(resp.data.pagination.lastPage) setLastPage(resp.data.pagination.lastPage);

                setProceduresData(x => {
                    const y = [...(x || [])];
                    for(let dt of resp.data.procedures) {
                        if(!y.some(z => z.procedureId === dt.procedureId)) y.push(dt);
                    }
                    y.sort((a, b) => {
                        const a_ = a.title.toLowerCase();
                        const b_ = b.title.toLowerCase();
                        if (a_ < b_)
                            return -1;
                        if (a_ > b_)
                            return 1;
                        return 0;
                    });
                    console.log("new_y: ", y);
                    return y;
                });
            }
        } catch(err) {
            console.error(err);
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        } finally {
            setLoadingMore(false);
        }
    }

    const switchPage = newPage => {
        console.log("switchPage: ", newPage);
        setPagination(x => ({
            ...x, page: newPage
        }))
    }

    const refresh = () => {
        setProceduresData(null);
        setLastPage(1);
        setPagination({page: 1, size: 3});
        fetchProcedures();
    }

    useEffect(() => {
        fetchProcedures();
    }, [pagination]);

    return (
        proceduresData === null
        ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner animation="border" />
            </div>
        :
        <InfiniteScroll
            pageStart={1}
            loadMore={switchPage}
            hasMore={pagination.page < lastPage}
            loader={null}
            useWindow={true}
        >
            {showAddProcedure &&
                <AddProcedurePopup open={true}
                    onClose={() => {
                        setShowAddProcedure(false);
                    }}
                    onAdd={() => {
                        setShowAddProcedure(false);
                        refresh();
                    }}
                />}

            {showEditProcedure &&
                <EditProcedureNamePopup open={true} procedureData={showEditProcedure}
                        onClose={() => setShowEditProcedure(null)}
                        onSave={() => {
                            setShowEditProcedure(null)
                            refresh();
                        }}
                    />}


            {showDeleteProcedure &&
                <DeleteProcedureConfirmationPopup open={true} procedureId={showDeleteProcedure}
                    onDelete={() => {
                        setShowDeleteProcedure(false);
                        refresh();
                    }}
                    onClose={() => {
                        setShowDeleteProcedure(false);
                    }} />}

            <div className="row">
                <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2 className={styles.contentTitle}>Manage Procedures</h2>
                    <div>
                        <button className={styles.primaryBtn} style={{
                                padding: '8px 30px', fontWeight: 'normal', width: '250px'
                            }} onClick={() => setShowAddProcedure(true)}>Add Procedure</button>
                    </div>
                </div>
            </div>

            <div className="row">
                {proceduresData.map(procedureItem =>
                    <div className="col-lg-6" style={{padding: '8px 8px'}}>
                        <ProcedureItem data={procedureItem} onEdit={() => setShowEditProcedure(procedureItem)}
                               key={`${procedureItem.procedureId}:${procedureItem.status}`}
                               onDelete={() => setShowDeleteProcedure(procedureItem.procedureId)}
                               onChange={newProcedureData => {
                                   setProceduresData(x =>
                                       x.map(yItem => yItem.procedureId === newProcedureData.procedureId ? newProcedureData : yItem)
                                   )
                               }}
                        />
                    </div>)}
            </div>

                {proceduresData?.length === 0 &&
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'grey', paddingTop: '30px'}}>
                        No records!
                    </div>}

            {loadingMore &&
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Spinner animation="border"/>
                </div>}

        </InfiniteScroll>
    )
}


const ProceduresPage = ({}) => {

    return (
        <div id="wrapper">
            <Sidebar/>
            <Header />
            <section id="content-wrapper" className={styles.content}>
                <Procedures />
            </section>
        </div>
    )
}


export default ProceduresPage;
