import Sidebar from "../../../app/components/sidebar";
import Header from "../../../app/components/header";
import styles from "../surveys.module.scss";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import QuestionItem from "./QuestionItem";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import sampleSurveyData from "../sample-surveys.json";
import AssignedToUsers from "./AssignedToUsers";
import QuestionView from "../view-question-popup/QuestionView";
import AddQuestionPopup from "../add-question-popup";
import DeleteQuestionConfirmationPopup from "../delete-question-confirmation-popup";
import AddSurveyPopup from "../add-survey-popup";
import {fetchGetRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import EditSurveyTitlePopup from "../edit-survey-title-popup";

const ViewSurvey = ({

}) => {
    const { surveyId } = useParams();
    const [surveyData, setSurveyData] = useState(null);
    const [surveyQuestions, setSurveyQuestions] = useState(null);
    const [surveyUsers, setSurveyUsers] = useState(null);

    const [showViewQuestion, setShowViewQuestion] = useState(null);
    const [showEditQuestion, setShowEditQuestion] = useState(null);
    const [showDeleteQuestion, setShowDeleteQuestion] = useState(null);
    const [showAddQuestion, setShowAddQuestion] = useState(false);
    const [showEditSurvey, setShowEditSurvey] = useState(false);

    const fetchSurveyData = async () => {
        try {
            const resp = await fetchGetRequest(endpoints.surveys.get(surveyId));
            if(resp.data.survey) {
                setSurveyData(resp.data.survey);
            }
        } catch(err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        }
    }

    const fetchSurveyQuestions = async () => {
        try {
            const resp = await fetchGetRequest(endpoints.surveys.questions.getAll(surveyId));
            if(Array.isArray(resp.data.survey_questions)) {
                setSurveyQuestions(resp.data.survey_questions);
            }
        } catch(err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        }
    }

    const fetchSurveyUsers = async () => {
        try {
            const resp = await fetchGetRequest(endpoints.surveys.getSurveyUsers(surveyId));
            if(Array.isArray(resp.data.surveyUsers)) {
                setSurveyUsers(resp.data.surveyUsers);
            }
        } catch(err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        }
    }

    const refresh = () => {
        fetchSurveyData();
        fetchSurveyQuestions();
        fetchSurveyUsers();
    }

    useEffect(() => {
        fetchSurveyData();
        fetchSurveyQuestions();
        fetchSurveyUsers();
    }, []);

    return (
        (surveyData == null || surveyQuestions == null)
        ? <div style={{display: 'flex', justifyContent: 'center'}}>
                <Spinner animation="border"/>
            </div>
        :
        <>
            <div>
                {showViewQuestion &&
                    <QuestionView questionData={showViewQuestion}
                        heading={`Question ${(surveyQuestions?.findIndex(x => x.surveyQuestionId === showViewQuestion.surveyQuestionId) || 0) + 1}`}
                        onEdit={() => setShowEditQuestion(showViewQuestion)}
                        onClose={() => setShowViewQuestion(null)}
                    />}
                {showEditQuestion &&
                    <AddQuestionPopup questionData={showEditQuestion} surveyId={surveyId}
                        onClose={() => setShowEditQuestion(null)}
                        onDelete={() => {
                            setShowEditQuestion(null);
                            setShowDeleteQuestion(showEditQuestion);
                        }}
                        onSave={() => {
                            setShowEditQuestion(null);
                            refresh();
                        }}
                        heading={`Question ${(surveyQuestions?.findIndex(x => x.surveyQuestionId === showEditQuestion.surveyQuestionId) || 0) + 1}`}
                    />}

                {showAddQuestion &&
                    <AddQuestionPopup surveyId={surveyId}
                        heading={`Question ${(surveyQuestions?.length || 0) + 1}`}
                        onClose={() => setShowAddQuestion(null)}
                        onSave={() => {
                            setShowAddQuestion(null);
                            refresh();
                        }}
                    />}

                {showEditSurvey &&
                    <EditSurveyTitlePopup surveyData={showEditSurvey}
                            onClose={() => setShowEditSurvey(null)}
                            onSave={() => {
                                setShowEditSurvey(null)
                                refresh();
                            }}
                        />}

                {showDeleteQuestion &&
                    <DeleteQuestionConfirmationPopup
                        surveyQuestionId={showDeleteQuestion?.surveyQuestionId}
                        open={!!showDeleteQuestion}
                        onDelete={() => {
                            refresh();
                            setShowDeleteQuestion(null);
                        }}
                        onClose={() => setShowDeleteQuestion(null)}
                    />}

                <div className="row">
                    <div className="col-lg-12"
                         style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Link to="/surveys" style={{display: 'flex', alignItems: 'center'}}>
                                <img src="/images/arrowhead-left.svg"
                                     style={{marginRight: '32px', marginLeft: '10px'}} />
                            </Link>
                            <h6 className={styles.surveyTitle} style={{fontSize:'28px',marginRight:"12px",marginTop:'4px'}}>{surveyData.title}</h6>
                            <h6 style={{marginBottom: 0, color: '#B9B9B9',fontSize:'20px'}}>{surveyData?.totalCompleted || "0"} Completed Surveys</h6>
                        </div>
                        <div style={{display:"flex"}}>
                            <img src="/images/blue-plusicon.svg" className={styles.clickable}
                                 style={{height: '40px', width: 'auto', marginRight: '15px'}}
                                 onClick={() => setShowAddQuestion(true)}/>
                            <button className={styles.primaryBtn} style={{padding: '8px 50px', fontWeight: 'normal'}}
                                    disabled={!surveyData} onClick={() => setShowEditSurvey(surveyData)}>
                                Edit
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row" style={{paddingTop: '30px'}}>
                    {surveyQuestions.map((question, index) =>
                        <div className="col-6" style={{marginBottom:55}} >
                            <QuestionItem key={question.surveyQuestionId}
                                onView={() => setShowViewQuestion(question)}
                                onEdit={() => setShowEditQuestion(question)}
                                order={index+1} {...question}
                            />
                        </div>)}
                    {surveyQuestions?.length == 0 &&
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'grey',
                            paddingTop: '30px'
                        }}>
                            No records!
                        </div>}
                </div>
                <br/><br/><br/>
                <AssignedToUsers assignedUsers={surveyUsers} surveyId={surveyData.surveyId}
                     onRefresh={() => fetchSurveyUsers()}
                />
            </div>
        </>
    )
}


const ViewSurveyPage = ({}) => {
    return (
        <div id="wrapper">
            <Sidebar/>
            <Header/>
            <section id="content-wrapper" className={styles.content}>
                <ViewSurvey/>
            </section>
        </div>
    )
}

export default ViewSurveyPage;
