import Table from 'react-bootstrap/Table';
import React, {useState, useEffect} from "react";
import Spinner from "react-bootstrap/Spinner";
import toastMessage from "../../utils/toastMessage";
import constants from "../../utils/constants";
import sampleUsersData from "./users-data.json";
import Sidebar from "../../app/components/sidebar";
import Header from "../../app/components/header";
import styles from "./users.module.scss";
import Searchbox from "../../app/components/searchbox";
import DeleteUser from "./delete-user";
import EditUser from "./edit-user";
import ViewUser from "./view-user";
import {fetchGetRequest} from "../../utils/network";
import endpoints from "../../utils/endpoints";
import InfiniteScroll from 'react-infinite-scroller';
import {useNavigate} from "react-router-dom";
import debounce from "../../utils/debounce";
import {ref} from "yup";
import {getFormattedDate} from "../../utils";
import FiltersPopup from "../surveys/filters-popup";
import moment from 'moment';

const UsersListing = ({}) => {
    const [usersData, setUsersData] = useState(null);
    const [searchInputValue, setSearchInputValue] = useState("");

    const [showDeleteUserModal, setShowDeleteUserModal] = useState(null);
    const [showEditUserModal, setShowEditUserModal] = useState(null);

    const [pagination, setPagination] = useState({page:1, size: 50});
    const [lastPage, setLastPage] = useState(1);
    const [loadingMore, setLoadingMore] = useState(false);
    const [ordering, setOrdering] = useState({orderBy: 'firstName', orderType: 'ASC'})
    const [pageCountOptions, setPageCountOptions] = useState([pagination.size, 20, 50]);
    const [lastRefresh, setLastRefresh] = useState(0);
    const [filters, setFilters] = useState({})
    const [showFilters, setShowFilters] = useState(false);

    const navigate = useNavigate();

    const fetchUsersData = async () => {
        const keyword = searchInputValue;
        setLoadingMore(true);
        try {
            const params = {...pagination, ...filters}
            if(keyword) params.keywords = keyword;
            const resp = await fetchGetRequest(endpoints.users.getAllUsers, {params});
            if(searchInputValue !== keyword) return;
            if(Array.isArray(resp.data.users)) {
                if(resp.data?.pagination?.lastPage) {
                    setLastPage(resp.data.pagination.lastPage);
                }
                const _newUsers = resp.data.users;
                setUsersData(x => {
                    const y = x ? [...x] : [];
                    for(let _newUser of _newUsers) {
                        if(x && x.some(z => z.userId === _newUser.userId))
                            continue;
                        y.push(_newUser);
                    }
                    // Sort alphabetically
                    y.sort(function (a, b) {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
                    return y;
                });
            }
        } catch(err) {
            console.error(err);
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        } finally {
            setLoadingMore(false);
        }
    }

    const switchPage = newPage => {
        console.log("switchPage: ", newPage);
        setPagination(x => ({
            ...x, page: newPage
        }))
    }

    const refresh = () => {
        setLastRefresh((new Date()).getTime());
        setUsersData(null);
        setLastPage(1);
        setPagination(x => ({...x, page: 1}));
        // fetchUsersData();
    }

    const debouncedRefresh = debounce(refresh, 500);

    useEffect(() => {
        fetchUsersData()
    }, [pagination, filters]);

    return (
        <InfiniteScroll key={lastRefresh}
            pageStart={pagination.page}
            loadMore={switchPage}
            hasMore={pagination.page < lastPage}
            loader={null}
            useWindow={true}
        >
            <div className="row">
                {showDeleteUserModal &&
                    <DeleteUser open={!!showDeleteUserModal} userId={showDeleteUserModal.userId}
                        onClose={() => setShowDeleteUserModal(null)}
                        onDelete={() => {
                            setShowDeleteUserModal(null);
                            refresh();
                        }}
                    />}

                {showEditUserModal &&
                    <EditUser open={!!showEditUserModal} userData={showEditUserModal}
                        onSuccess={() => {
                            setShowEditUserModal(null);
                            refresh();
                        }}
                        onClose={() => setShowEditUserModal(null)}
                    />}

                {showFilters &&
                    <FiltersPopup filters={filters}
                          onClose={() => setShowFilters(false)}
                          setFilters={(newFilters) => {
                              setShowFilters(false);
                              setFilters(newFilters);
                              refresh();
                          }}
                    />}

                <div className="col-lg-12">
                    <h2 className={styles.contentTitle}>Users</h2>
                    <div>
                        <div style={{
                            display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '35px', gap: '10px'
                        }}>
                            <img src="/images/filter_icon.svg" alt="Filter" className={styles.hoverable}
                                 onClick={() => setShowFilters(true)}
                            />
                            <Searchbox value={searchInputValue} autoFocus
                                       onChange={(e) => {
                                           setSearchInputValue(e.target.value);
                                           debouncedRefresh();
                                       }}
                                       onClick={() => refresh()}
                            />
                        </div>
                    </div>

                    {(usersData !== null && usersData.length > 0) &&
                        <Table responsive hover className={styles.usersListing}>
                            <thead>
                            <tr>
                                <th>User's Full Name</th>
                                <th>Procedure</th>
                                <th>Task List</th>
                                <th>Procedure Date</th>
                                <th></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {usersData.map((userData) =>
                                <tr key={userData.id} className={styles.hoverable}
                                    onClick={() => navigate(`/users/${userData.userId}`)}>
                                    <td>{userData.fullName}</td>
                                    <td>{userData?.procedureName || "N/A"}</td>
                                    <td>{userData?.taskSetName || "N/A"}</td>
                                    <td>{userData?.procedureDateTime?moment.utc(userData?.procedureDateTime).local().format("MM/DD/YYYY") : "N/A"}</td>
                                
                                    <td>
                                    <img src={userData?.accountStatus==='Approved'?"/images/activeStatusIcon.svg":'/images/deactivateStatusIcon.svg'} style={{marginTop: '-3px'}} />
                                    </td>
                                    <td>
                                        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '15px'}}>
                                            <button className={styles.primaryBtn} type="submit"
                                                    style={{padding: '5px 30px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setShowEditUserModal(userData)
                                                }}>
                                                Edit
                                            </button>
                                            <button style={{
                                                    background: '#D30B0B', borderRadius: '100px', border: 'none',
                                                    width: '40px', height: '40px'
                                                }}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setShowDeleteUserModal(userData)
                                                }}>
                                                <img src="/images/delete-icon.svg" style={{marginTop: '-3px'}} alt="delete icon" />
                                            </button>
                                            <button style={{ background: 'transparent', border: 'none' }}>
                                                <img src="/images/arrowhead-right.svg" alt="expand" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>}

                    {(usersData?.length === 0) &&
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            No Records Found!
                        </div>}

                    {loadingMore &&
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Spinner animation="border"/>
                        </div>}

                </div>
            </div>
        </InfiniteScroll>
    )
}

const UsersListingPage = ({}) => {
    return (
        <div id="wrapper">
            <Sidebar/>
            <Header/>
            <section id="content-wrapper" className={styles.content}>
                <UsersListing/>
            </section>
        </div>
    )
}


export default UsersListingPage;
