/* eslint-disable import/no-anonymous-default-export */
export default {
  API_BASE_ENDPOINT: process.env.REACT_APP_API_BASE_ENDPOINT,
  USER_TYPE: {
    ADMINISTRATOR: "Administrator",
  },
  TOAST_TYPE: {
    SUCCESS: "success",
    ERROR: "error",
  },
  ITEM_TYPE: ["Shirt", "Pant", "Shoe"],
  GENDER: ["Male", "Female"],
};
