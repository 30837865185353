import Modal from "react-bootstrap/Modal";
import procedures_styles from "../../../pages/procedures/procedures.module.scss";
import React from "react";
import Spinner from "react-bootstrap/Spinner";

const LogoutConfirmationPopup = ({ open, onClose, onLogout, loggingOut }) => {
    return (
        <Modal show={open} scrollable>
            <Modal.Header style={{borderBottom: 'none', display: 'flex', alignItems: 'flex-start'}}>
                <Modal.Title style={{color: '#666666', textAlign: 'center', width: '100%', marginTop: '30px'}}>Log out</Modal.Title>
                <img className={procedures_styles.clickable} src="/images/close-icon.png"
                     style={{width: '30px', height: '30px'}} onClick={onClose} />
            </Modal.Header>
            <Modal.Body>
                <p style={{color: '#666666'}}>
                    Are you sure you want to log out? You will have to log back in to your account later.
                </p>
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'center', borderTop: 'none'}}>
                <button className={procedures_styles.secondaryBtn} style={{padding: '5px 30px'}} disabled={loggingOut} onClick={onClose}>Cancel</button>
                {loggingOut &&
                    <div style={{width: '200px', height: '40px', display: 'flex', justifyContent: 'center'}}>
                        <Spinner animation="border" />
                    </div>}
                {!loggingOut &&
                    <button className={procedures_styles.primaryBtn} style={{padding: '5px 30px'}} onClick={onLogout}>
                        Log out
                    </button>}
            </Modal.Footer>
        </Modal>
    )
}

export default LogoutConfirmationPopup;
