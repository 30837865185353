import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import procedures_styles from "../procedures.module.scss";
import React, {useState} from "react";
import {RenderProcedureStepForm} from "../add-procedure-popup";
import DeleteProcedureStepConfirmationPopup from "../delete-step-popup";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import {fetchPatchRequest, fetchPostRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import Spinner from "react-bootstrap/Spinner";

const AddStepPopup = ({
      heading, stepData, open, onClose, procedureData, onSave, procedureSetId
}) => {
    const [stepItem, setStepItem] = useState(stepData || {
        when: "01:00:00", isBeforeProcedure: false, description: "", procedureStepImage: null
    });
    const [showDeleteStep, setShowDeleteStep] = useState(false);
    const [savingData, setSavingData] = useState(false);

    const addStep = async () => {
        setSavingData(true);
        const payload = new FormData();
        payload.append("procedureId", procedureData.procedureId);
        payload.append("when", stepItem.when);
        payload.append("isBeforeProcedure", stepItem.isBeforeProcedure);
        payload.append("description", stepItem.description);
        payload.append("procedureStepImage", stepItem.procedureStepImage);
        if(procedureSetId)
            payload.append("procedureSetId", procedureSetId);
        // else
        //     payload.append("procedureSetId", null);
        try {
            const resp = await fetchPostRequest(endpoints.procedures.steps.create, payload);
            if(resp.status < 400) {
                toastMessage(constants.TOAST_TYPE.SUCCESS, resp.data.message);
                onSave?.();
            }
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setSavingData(false);
        }
    }

    const updateStep = async () => {
        setSavingData(true);
        const payload = new FormData();
        payload.append("procedureId", procedureData.procedureId);
        payload.append("when", stepItem.when);
        payload.append("isBeforeProcedure", stepItem.isBeforeProcedure);
        payload.append("description", stepItem.description);
        if(procedureSetId)
            payload.append("procedureSetId", procedureSetId);
        if(stepItem.procedureStepImage)
            payload.append("procedureStepImage", stepItem.procedureStepImage);
        try {
            const resp = await fetchPatchRequest(endpoints.procedures.steps.update(stepData.procedureStepId), payload);
            if(resp.status < 400) {
                toastMessage(constants.TOAST_TYPE.SUCCESS, resp.data.message);
                onSave?.();
            }
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setSavingData(false);
        }
    }


    return (
        <>
            {showDeleteStep &&
                <DeleteProcedureStepConfirmationPopup open={showDeleteStep} procedureStepId={stepData.procedureStepId}
                        onClose={() => setShowDeleteStep(false)}
                        onDelete={() => {
                            setShowDeleteStep(false);
                            onSave?.();
                        }}
                    />}
            <Modal show={showDeleteStep ? false : open} size="lg" scrollable>
                <Modal.Header style={{borderBottom: 'none'}}>
                    <Modal.Title>{stepData ? "Edit" : "Add"} Step</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={e => {
                        e.preventDefault(); e.stopPropagation();
                        if(stepData) {
                            updateStep();
                        } else {
                            addStep();
                        }
                    }} id="add-step-form">
                        <Form.Group className="mb-5">
                            <Form.Label>Procedure Title</Form.Label>
                            <Form.Control type="text" placeholder="Procedure Title" value={procedureData.title} readOnly />
                        </Form.Group>
                            <RenderProcedureStepForm isAdd={!stepData} heading={heading}
                                 stepData={stepItem} setStepData={setStepItem}
                            />
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{display: 'flex', justifyContent: 'center'}}>
                    <button className={procedures_styles.secondaryBtn} style={{padding: '5px 30px'}} onClick={onClose}>Cancel</button>
                    {stepData &&
                        <button className={procedures_styles.warningBtn} style={{padding: '5px 30px'}}
                                onClick={() => setShowDeleteStep(true)}>Delete</button>}
                    <button form="add-step-form" className={procedures_styles.primaryBtn} style={{
                            padding: '5px 30px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                        {savingData &&
                            <Spinner animation="border" style={{marginRight: '20px'}}/>}
                        Done
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default AddStepPopup;
