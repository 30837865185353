import React from "react";
import { NavLink } from "react-router-dom";
import logo_img from "../../../assets/images/small-logo.png"

const Header = () => {
  const toggle = () => {
    const wrapper = document.getElementById('wrapper')
    if(!wrapper) return;
    if(wrapper.classList.contains('toggled')) {
      wrapper.classList.remove('toggled')
    } else {
      wrapper.classList.add('toggled')
    }
  }

  return (
    <div id="navbar-wrapper">
      <nav className="navbar navbar-inverse">
          <div className="navbar-header" style={{ display: 'flex', justifyContent: 'center', width: '100%'}}>
            <img src={logo_img} alt="Young Automotive Group" style={{width: 'auto', height: '69px'}} />
          </div>
      </nav>
    </div>
  );
};
export default Header;
