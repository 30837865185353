import styles from "../surveys.module.scss";
import React, {useState} from "react";
import RangeSlider from 'react-bootstrap-range-slider';
import '../../slider-styles.scss';

const QuestionHeader = ({
    order, questionType, question, onView, onEdit
}) => {

    const showQuestionType = () => {
        if(questionType === "single-choice") {
            return "Multiple Choice - Select one";
        } else if(questionType === "multiple-choice") {
            return "Multiple Choice - Select Multiple";
        } else if(questionType === "slider") {
            return "Slider";
        } else if(questionType === "short-answer") {
            return "Short Answer";
        }
    }

    return (
        <>
            <div style={{display: 'flex'}}>
                <h5 style={{fontWeight: 'bold', flex:1}}>Question {order}</h5>
                <div style={{display: 'flex'}}>
                    <div className={styles.clickable} onClick={onView} style={{
                        display: 'flex', alignItems: 'center', marginLeft: '15px', marginRight: '15px'
                    }}>
                        <img src="/images/eye.svg"/>
                    </div>
                    <div className={styles.clickable} onClick={onEdit} style={{
                        display: 'flex', alignItems: 'center', marginLeft: '15px', marginRight: '15px'
                    }}>
                        <img src="/images/edit-pencil.svg"/>
                    </div>
                </div>
            </div>
            <p style={{fontWeight: 'normal', marginBottom: 0}}>{question}</p>
            <p style={{color: '#B9B9B9', marginBottom: '5px'}}>{showQuestionType()}</p>
        </>
    )
}

const McqSelectOne = ({
  ...props
}) => {
    return (
        <div>
            <QuestionHeader {...props} />
            <p style={{fontWeight: 'normal', color: '#B9B9B9'}}>
                Options:&nbsp;
                {props.givenAnswerCounts.map((optionItem, i) =>
                    <>
                        <span>{optionItem?.option}</span>
                        <span style={{color: '#013399', fontWeight: 'bold'}}> ({optionItem?.count || 0})</span>
                        {i < props.options.length-1 ? <span>,&nbsp;</span> : null}
                    </>)}
            </p>
            <br />
        </div>
    )
}

const ShortAnswer = ({
    ...props
}) => {
    return (
        <div>
            <QuestionHeader {...props} />
        </div>
    )
}

export const SliderAnswer = ({
    ...props
}) => {
    const avg_answer = props.avg_answer || 5;
    return (
        <>
            <RangeSlider step={1} max={10} tooltip="off"
                         value={avg_answer} variant="primary"
            />
            <div style={{position: 'relative'}}>
                <div style={{position: 'absolute', left: '0%', color: '#B9B9B9'}}>{props.options?.[0] +` (${props?.givenAnswerCounts[0]?.count || 0})`}</div>
                <div style={{position: 'absolute', right: '0%', color: '#B9B9B9'}}>{props.options?.[1] +` (${props?.givenAnswerCounts[1]?.count || 0})`}</div>
                <div style={{
                    position: 'absolute', left: `${avg_answer * 10}%`, top: '10px',
                    color: '#013399', transform: 'translateX(-50%)'
                }}>Avg. Answer</div>
            </div>
        </>
    )
}

const SliderQuestion = ({
    ...props
}) => {

    return (
        <div>
            <QuestionHeader {...props} />
            <SliderAnswer {...props} />
        </div>
    )
}

const QuestionItem = ({
  ...props
}) => {
    const type = props.questionType;
    return (
        type === "single-choice"
        ? <McqSelectOne {...props} />
        : (type === "short-answer"
            ? <ShortAnswer {...props} />
            : (type === "slider"
                ? <SliderQuestion {...props} />
                : (type === "multiple-choice"
                    ? <McqSelectOne {...props} />
                    : null)))
    )
}

export default QuestionItem;
