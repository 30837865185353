import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import addQuestion_styles from "../add-question-popup/addQuestionPopup.module.scss";
import styles from "../surveys.module.scss";
import procedures_styles from "../../procedures/procedures.module.scss";
import Spinner from "react-bootstrap/Spinner";
import {FormGroup} from "react-bootstrap";
import {QuestionEditForm} from "../add-question-popup";
import {fetchDeleteRequest, fetchPostRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";


const AddSurveyPopup = ({
    open, onClose, onAdd
}) => {
    const [step, setStep] = useState(0);
    const [savingData, setSavingData] = useState(false);
    const [canceling, setCanceling] = useState(false);
    const [survey, setSurvey] = useState({
        status: 'Active', title: '', questions: []
    })


    const addSurvey = async () => {
        setSavingData(true);
        if(survey.title?.trim()){
            try {
                const resp = await fetchPostRequest(endpoints.surveys.create, {
                    'status': survey.status, 'title': survey.title?.trim()
                })
                setSurvey(x => ({
                    ...x, surveyId: resp.data.surveyId
                }))
                appendQuestion();
            } catch (err) {
                toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
                console.error(err);
            } finally {
                setSavingData(false);
            }
        }else{
            toastMessage(constants.TOAST_TYPE.ERROR, "Please fill in this field");
            setSavingData(false);
        }
        
    }

    const deleteSurvey = async () => {
        setCanceling(true);
        try {
            const resp = await fetchDeleteRequest(endpoints.surveys.delete(survey.surveyId));
            if(resp.status < 400) {
                // toastMessage(constants.TOAST_TYPE.SUCCESS, resp.data.message);
            }
            onClose?.();
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setCanceling(false);
        }
    }

    const appendQuestion = () => {
        setSurvey(x => {
            const y = {...x};
            y.questions.push({options: [""], question: '', questionType: 'single-choice'});
            return y;
        })
        setStep(x => x + 1);
    }

    const addQuestion = async (onSuccess) => {
        setSavingData(true);
        const lastQuestion = survey.questions[survey.questions.length - 1];
        const payload = {
            surveyId: survey.surveyId, ...lastQuestion
        }
        if(payload.questionType === 'short-answer')
            delete payload['options']
        try {
            const resp = await fetchPostRequest(endpoints.surveys.questions.create, payload);
            if(resp.status < 400) {
                toastMessage(constants.TOAST_TYPE.SUCCESS, resp.data.message);
                appendQuestion();
            }
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setSavingData(false);
        }
    }

    const setQuestionData = (x) => {
        if(typeof x === 'function') {
            setSurvey(y => {
                const z = {...y};
                z.questions[z.questions.length - 1] = x(z.questions[z.questions.length - 1]);
                return z;
            })
        } else {
            setSurvey(y => {
                const z = {...y};
                z.questions[z.questions.length - 1] = x;
                return z;
            })
        }
    }

    return (
        <Modal show={true} scrollable size="lg">
            <Modal.Header style={{
                borderBottom: 'none', display: 'flex', alignItems: 'center',  paddingTop: '40px',
                paddingBottom: '20px', paddingLeft: '40px', paddingRight: '40px'
            }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <h2>Add a Survey</h2>
                </div>
            </Modal.Header>
            <Modal.Body style={{maxHeight: '90vh'}}>
                <Form className={addQuestion_styles.addQuestionForm} id="add-survey-form"
                      style={{paddingLeft: '25px', paddingRight: '25px'}}
                      onSubmit={e => {
                          e.preventDefault(); e.stopPropagation();
                          if(step === 0) {
                              addSurvey();
                          } else {
                              addQuestion();
                          }
                      }}>
                    <Form.Group className="mb-3 mt-3">
                        <Form.Label>Survey Title</Form.Label>
                        <Form.Control type="text" placeholder="Survey Title" value={survey?.title} readOnly={step > 0} required={step === 0}
                              onChange={e => setSurvey(x => ({...x, title: e.target.value}))} maxLength={65} />
                    </Form.Group>
                    {step > 0 &&
                        <>
                            <QuestionEditForm isAdd={true} key={step} heading={`Question ${step}`}
                                  questionData={survey.questions[survey.questions.length - 1]}
                                  setQuestionData={setQuestionData}
                            />
                        </>}
                </Form>
            </Modal.Body>
            <Modal.Footer style={{
                display: 'flex', justifyContent: step === 0 ? 'flex-end' : 'space-around', borderTop: 'none',
                paddingLeft: '25px', paddingRight: '35px'
            }}>
                {step > 0 &&
                    <>
                        <button className={styles.secondaryBtn} style={{
                            padding: '5px 30px', width: '220px', display: 'flex',
                            justifyContent: 'center', alignItems: 'center'
                        }} onClick={deleteSurvey}>
                            {canceling &&
                                <Spinner animation="border" style={{marginRight: '20px'}}/>}
                            Cancel
                        </button>
                        <button className={procedures_styles.primaryBtn} style={{
                            padding: '5px 30px', background: '#4DCDCD', borderColor: '#4DCDCD',
                            display: 'flex', alignItems: 'center', justifyContent: 'center', width: '220px'
                        }} form="add-survey-form" disabled={savingData} type="submit">
                            {savingData &&
                                <Spinner animation="border" style={{marginRight: '20px'}}/>}
                            Add Question
                        </button>
                        <button className={styles.primaryBtn} style={{
                            padding: '5px 0px',
                            width: '220px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} onClick={onAdd}>
                            Done
                        </button>
                    </>}
                {step === 0 &&
                    <>
                        <button className={styles.secondaryBtn} style={{
                            padding: '5px 30px', width: '180px'
                        }} onClick={onClose} disabled={canceling}>
                            {canceling &&
                                <Spinner animation="border" style={{marginRight: '20px'}}/>}
                            Cancel
                        </button>
                        <button className={styles.primaryBtn} style={{
                            padding: '5px 0px',
                            width: '180px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} form="add-survey-form" type="submit" disabled={savingData||canceling}>
                            {savingData &&
                                <Spinner animation="border" style={{marginRight: '20px'}}/>}
                            Next
                        </button>
                    </>}
            </Modal.Footer>
        </Modal>
    )
}


export default AddSurveyPopup;
