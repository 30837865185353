import Modal from "react-bootstrap/Modal";
import React, {useState} from "react";
import procedures_styles from "../surveys.module.scss";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import {fetchDeleteRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import Spinner from "react-bootstrap/Spinner";


const DeleteSurveyConfirmationPopup = ({ open, onClose, onDelete, surveyId }) => {
    const [deleting, setDeleting] = useState(false);

    const deleteSurvey = async () => {
        setDeleting(true);
        try {
            const resp = await fetchDeleteRequest(endpoints.surveys.delete(surveyId))
            if(resp.status < 400) {
                toastMessage(constants.TOAST_TYPE.SUCCESS, resp?.data?.message);
            }
            onDelete?.();
            setTimeout(() => {
                onDelete?.();
            }, 1000);
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setDeleting(false);
        }
    }

    return (
        <Modal show={open} scrollable size="md">
            <Modal.Header style={{borderBottom: 'none', display: 'flex', alignItems: 'flex-start'}}>
                <Modal.Title style={{color: '#666666', textAlign: 'center', width: '100%', marginTop: '30px'}}>Delete Survey</Modal.Title>
                <img className={procedures_styles.clickable} src="/images/close-icon.png"
                     style={{width: '30px', height: '30px'}} onClick={onClose} />
            </Modal.Header>
            <Modal.Body>
                <p style={{color: '#666666'}}>
                    Are you sure you want to delete this Survey? This process cannot be undone.
                </p>
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'center', borderTop: 'none'}}>
                <button className={procedures_styles.warningBtn} style={{
                    padding: '5px 0px', width: '220px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                }} disabled={deleting} onClick={() => {
                    deleteSurvey();
                }}>
                    {deleting &&
                        <div>
                            <Spinner animation="border" style={{marginRight: '20px'}} />
                        </div>}
                    Delete Survey
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteSurveyConfirmationPopup;
