import React, {useEffect, useState} from "react";
import DeleteProcedureConfirmationPopup from "../../procedures/delete-procedure-confirmation-popup";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import faqstips_styles from "../faqs-and-tips.module.scss";
import DeleteFaqAndTipConfirmationPopup from "../delete-faq-and-tip-popup";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import {fetchPatchRequest, fetchPostRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import Spinner from "react-bootstrap/Spinner";
import {timeStrToWhen, whenToTimeStr} from "../../../utils/when";


const AddFaqAndTipPopup = ({open, onClose, faqTipData, onSave, procedureData}) => {
    const [faqTip, setFaqTip] = useState(faqTipData || {question: "", answer: "", when: "01:00:00", isBeforeProcedure: false});
    const [showDeleteFaqTip, setShowDeleteFaqTip] = useState(false);
    const [savingData, setSavingData] = useState(false);
    const [when, setWhen] = useState(timeStrToWhen(faqTip?.when || "01:00:00"));

    const updateProp = (prop, value) => {
        setFaqTip(x => ({
            ...x,
            [prop]: value
        }))
    }

    const addFaqAndTip = async () => {
        setSavingData(true);
        try {
            const body = {procedureId: procedureData.procedureId, ...faqTip};
            const resp = await fetchPostRequest(endpoints.faqTips.create, body);
            if(resp.status < 400) {
                toastMessage(constants.TOAST_TYPE.SUCCESS, resp.data.message);
                onSave?.();
            }
        } catch(err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setSavingData(false);
        }
    }

    const updateFaqAndTip = async () => {
        if(!faqTipData) return;
        setSavingData(true);
        try {
            const body = {procedureId: procedureData.procedureId, ...faqTip};
            const resp = await fetchPatchRequest(endpoints.faqTips.update(faqTipData.procedureTipId), body);
            if(resp.status < 400) {
                toastMessage(constants.TOAST_TYPE.SUCCESS, resp.data.message);
                onSave?.();
            }
        } catch(err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setSavingData(false);
        }
    }

    const countOptions = Array(30).fill().map((_, index) => index + 1);
    const duration_names = ["Day", "Week", "Hour"];

    const filters = {
        'day': (x) => x <= 30,
        'week': (x) => x <= 4,
        'hour': (x) => x <= 24
    }

    const updateCount = (e) => {
        const newCount = parseInt(e.target.value);
        setWhen(x => ({ ...x, count: newCount }))
    }

    const updateDurationName = (e) => {
        const newDurationName = e.target.value;
        setWhen(x => {
            let newCount = x.count;
            const counts_arr = countOptions.filter(filters[newDurationName]);
            if(counts_arr[counts_arr.length-1] > x.count) {
                newCount = 1;
            }
            const xx = ({...x, duration_name: newDurationName, count: newCount})
            return xx;
        })
    }

    useEffect(() => {
        console.log("||||>>>", whenToTimeStr(when.count, when.duration_name));
        updateProp("when", whenToTimeStr(when.count, when.duration_name));
    }, [when]);

    return (
        <>
            {showDeleteFaqTip &&
                <DeleteFaqAndTipConfirmationPopup open={true} faqTipData={faqTipData}
                    onClose={() => setShowDeleteFaqTip(false)}
                    onDelete={() => onSave()}
                />}

            <Modal show={showDeleteFaqTip ? false : open} size="lg" scrollable>
                <Modal.Header style={{borderBottom: 'none'}}>
                    <Modal.Title>{faqTipData ? "Edit" : "Add"} FAQ/Tip to {procedureData.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="add-faq-tip" onSubmit={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if(faqTipData) {
                            updateFaqAndTip();
                        } else {
                            addFaqAndTip()
                        }
                    }}>
                        <Form.Group className="mb-3">
                            <Form.Label>FAQ/Tip Title</Form.Label>
                            <Form.Control type="text" placeholder="FAQ/Tip Title" value={faqTip.question} required maxLength={255}
                                  onChange={e =>
                                      setFaqTip(x => ({ ...x, question: e.target.value}))}
                            />
                        </Form.Group>
                        <div style={{display: 'flex'}}>
                            <Form.Group className="mb-3">
                                <Form.Label style={{color: '#8E8E8E'}}>When</Form.Label>
                                <div style={{display: 'flex', gap: '12px'}}>
                                    <Form.Select value={when.count} onChange={updateCount} style={{minWidth: '80px'}}>
                                        {countOptions.filter(filters[when.duration_name])
                                            .map(val =>
                                            <option value={val}>{val}</option>)}
                                    </Form.Select>
                                    <Form.Select value={when?.duration_name} onChange={updateDurationName} style={{minWidth: '100px'}}>
                                        {duration_names.map(val =>
                                            <option value={val.toLowerCase()}>{val}{when.count > 1 && "s"}</option>)}
                                    </Form.Select>
                                    <Form.Select value={faqTip?.isBeforeProcedure ? 'true' : 'false'} style={{minWidth: '120px'}}
                                                 onChange={e =>
                                                     updateProp("isBeforeProcedure", e.target.value === 'true')}>
                                        <option value="true">Before</option>
                                        <option value="false">After</option>
                                    </Form.Select>
                                </div>
                            </Form.Group>
                        </div>
                        <Form.Group className="mb-3">
                            <Form.Label style={{color: '#8E8E8E'}}>Description</Form.Label>
                            <Form.Control style={{color: '#8E8E8E'}} as="textarea" rows={3} placeholder="Description" value={faqTip.answer} required
                                  onChange={e =>
                                      setFaqTip(x => ({ ...x, answer: e.target.value}))}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{display: 'flex', justifyContent: 'center', borderTop: 'none'}}>
                    <button className={faqstips_styles.secondaryBtn} style={{padding: '5px 30px'}} onClick={onClose}>Cancel</button>
                    {faqTipData &&
                        <button className={faqstips_styles.warningBtn} style={{padding: '5px 30px'}}
                                onClick={() => setShowDeleteFaqTip(true)}>Delete</button>}
                    <button className={faqstips_styles.primaryBtn} type="submit" form="add-faq-tip" style={{
                            padding: '5px 30px', display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }} disabled={savingData}>
                        {savingData &&
                            <Spinner animation="border" style={{marginRight: '20px'}}/>}
                        Done
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default AddFaqAndTipPopup;
