import Sidebar from "../../../app/components/sidebar";
import Header from "../../../app/components/header";
import React, {useEffect, useState} from "react";
import proceduresData_sample from "../../procedures/procedures-data.json";
import faqsAndTipsData_sample from "../faqsandtips-data.json";
import Spinner from "react-bootstrap/Spinner";
import styles from "../faqs-and-tips.module.scss";
import {Link, useParams} from "react-router-dom";
import AddFaqAndTipPopup from "../add-faq-and-tip-popup";
import {fetchGetRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import InfiniteScroll from 'react-infinite-scroller';
import {timeStrToOrderableNumber, timeStrToReadableString, whenToTimeStr} from "../../../utils/when";



const FaqAndTipItem = ({question, answer, when, isBeforeProcedure, onEdit}) => {
    return (
        <div className={styles.faqTipCard}>
            <div className={styles.info}>
                <h5 style={{marginBottom: '5px', wordBreak: 'break-word'}}>{question}</h5>
                <p style={{marginBottom: '7px', marginTop: '2px', color: 'grey'}}>{timeStrToReadableString(when, isBeforeProcedure)}</p>
                <p style={{color: '#B9B9B9', wordBreak: 'break-word'}}>{answer}</p>
            </div>
            <div className={`${styles.editBtn} ${styles.clickable}`} onClick={onEdit}>
                <img src="/images/edit-pencil.svg" />
            </div>
        </div>
    )
}


const ViewFaqsAndTips = () => {
    const {procedureId} = useParams();
    const [procedureData, setProcedureData] = useState(null);
    const [faqsAndTipsData, setFaqsAndTipsData] = useState(null);

    const [showAddFaqAndTip, setShowAddFaqAndTip] = useState(false);
    const [showEditFaqAndTip, setShowEditFaqAndTip] = useState(null);

    const [pagination, setPagination] = useState({page: 1, size: 20});
    const [lastPage, setLastPage] = useState(1);

    const [loadingMore, setLoadingMore] = useState(false);

    const fetchProcedureData = async () => {
        try {
            const resp = await fetchGetRequest(endpoints.procedures.getProcedure(procedureId));
            if(resp.data.procedure) {
                const data = resp.data.procedure;
                // data.steps = data.steps.sort((a, b) => {
                //     const x = a.orderNumber;
                //     const y = b.orderNumber;
                //     if (x < y) {
                //         return 1;
                //     }
                //     if (x > y) {
                //         return -1;
                //     }
                //     return 0;
                // })
                setProcedureData(data);
            }
        } catch(err) {
            console.error(err);
        }
    }

    const fetchFaqsAndTips = async () => {
        setLoadingMore(true);
        try {
            const params = pagination;
            const resp = await fetchGetRequest(endpoints.faqTips.getAllFaqTips(procedureId), {params});
            if(Array.isArray(resp.data.procedure_tips)) {
                if(resp.data.pagination?.lastPage) setLastPage(resp.data.pagination.lastPage);

                setFaqsAndTipsData(x => {
                    const y = [...(x || [])];
                    for(let dt of resp.data.procedure_tips) {
                        if(!y.some(z => z.procedureTipId === dt.procedureTipId)) y.push(dt);
                    }
                    y?.sort((a, b) => {
                        const a_ = timeStrToOrderableNumber(a.when, a.isBeforeProcedure);
                        const b_ = timeStrToReadableString(b.when, b.isBeforeProcedure);
                        if (a_ < b_)
                            return -1;
                        if (a_ > b_)
                            return 1;
                        return 0;
                    });
                    console.log("new_y: ", y);
                    return y;
                });
            }
        } catch(err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setLoadingMore(false);
        }
    }

    useEffect(() => {
        fetchProcedureData();
        fetchFaqsAndTips();
    }, []);

    const switchPage = newPage => {
        console.log("switchPage: ", newPage);
        setPagination(x => ({
            ...x, page: newPage
        }))
    }

    const refresh = () => {
        setFaqsAndTipsData(null);
        setLastPage(1);
        setPagination({page: 1, size: 99});
        fetchFaqsAndTips();
    }


    return (
        (faqsAndTipsData === null || procedureData === null)
            ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner animation="border" />
            </div>
            :
            <InfiniteScroll
                pageStart={1}
                loadMore={switchPage}
                hasMore={pagination.page < lastPage}
                loader={null}
                useWindow={true}
            >
                {showAddFaqAndTip &&
                    <AddFaqAndTipPopup open={true} procedureData={procedureData}
                       onClose={() => setShowAddFaqAndTip(false)}
                       onSave={() => {
                           refresh();
                           setShowAddFaqAndTip(false);
                       }}
                    />}
                {showEditFaqAndTip &&
                    <AddFaqAndTipPopup open={true} faqTipData={showEditFaqAndTip} procedureData={procedureData}
                        onClose={() => setShowEditFaqAndTip(false)}
                        onSave={() => {
                            refresh();
                            setShowEditFaqAndTip(false);
                        }}
                    />}

                <div className="row">
                    <div className="col-lg-12" style={{
                        display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '40px'
                    }}>
                        <h2 className={styles.contentTitle} style={{wordBreak: 'break-word'}}>Manage FAQs and Tips - {procedureData.title}</h2>
                        <div>
                            <button className={styles.primaryBtn} style={{
                                    padding: '8px 30px', fontWeight: 'normal', width: '200px'
                                }}
                                    onClick={() => setShowAddFaqAndTip(true)}>Add a FAQ/Tip</button>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {faqsAndTipsData.map(faqAndTipItem =>
                        <div className="col-lg-12" style={{ padding: '8px 10px' }}>
                            <FaqAndTipItem {...faqAndTipItem}
                                   onEdit={() => setShowEditFaqAndTip(faqAndTipItem)} />
                        </div>)}

                    {faqsAndTipsData && faqsAndTipsData.length === 0 &&
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'grey', paddingTop: '30px'}}>
                            No records!
                        </div>}

                    {!faqsAndTipsData &&
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Spinner animation="border"/>
                        </div>}

                </div>
            </InfiniteScroll>
    )
}

const ViewFaqsAndTipsPage = () => {
    return (
        <div id="wrapper" className="">
            <Sidebar/>
            <Header />
            <section id="content-wrapper">
                <ViewFaqsAndTips />
            </section>
        </div>
    )
}

export default ViewFaqsAndTipsPage;
