

import Modal from "react-bootstrap/Modal";
import React, {useState} from "react";
import procedures_styles from "../procedures.module.scss";
import {fetchDeleteRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";

const DeleteProcedureStepConfirmationPopup = ({ open, onClose, procedureStepId, onDelete,stepsData,procedureData }) => {
    const [deleting, setDeleting] = useState(false);
    const navigate = useNavigate()
    const deleteProcedureStep = async () => {
        setDeleting(true);
        try {
            const resp = await fetchDeleteRequest(endpoints.procedures.steps.delete(procedureStepId))
            if(resp.status < 400) {
                toastMessage(constants.TOAST_TYPE.SUCCESS, resp?.data?.message);
            }
            if(resp.data?.navigateToProcedurePage){
                navigate("/")
            }
            onDelete?.();
        } catch (err) {
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
            console.error(err);
        } finally {
            setDeleting(false);
        }
    }

    return (
        <Modal show={open} scrollable>
            <Modal.Header style={{borderBottom: 'none', display: 'flex', alignItems: 'flex-start'}}>
                <Modal.Title style={{color: '#666666', textAlign: 'center', width: '100%', marginTop: '30px'}}>Delete Step</Modal.Title>
                <img className={procedures_styles.clickable} src="/images/close-icon.png"
                     style={{width: '30px', height: '30px'}} onClick={onClose} />
            </Modal.Header>
            <Modal.Body>
                <p style={{color: '#666666'}}>
                    {procedureData?.numberOfTotalSteps ===1 ?"Deleting the last step in a procedure will delete the procedure. Are you sure you want to continue?":"Are you sure you want to delete this procedure step? This process is irreversible and this procedure step data will be lost."}  
                </p>
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'center', borderTop: 'none'}}>
                <button className={procedures_styles.secondaryBtn} style={{padding: '5px 30px', width: '220px'}} onClick={onClose}>Cancel</button>
                <button className={procedures_styles.warningBtn} style={{
                        padding: '5px 0px', width: '220px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }} disabled={deleting} onClick={() => {
                        deleteProcedureStep();
                    }}>
                    {deleting &&
                        <div>
                            <Spinner animation="border" style={{marginRight: '20px'}} />
                        </div>}
                    Delete Step
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteProcedureStepConfirmationPopup;
