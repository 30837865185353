/* eslint-disable import/no-anonymous-default-export */
export default {
  auth: {
    login: "/auth/login",
    renewToken: "/auth/renew-token",
    logout: "/auth/logout",
    confirmAccount: "/auth/confirm-account",
    changePassword: "/auth/change-password",
    forgotPassword: "/auth/forgot-password",
    resetPassword: "/auth/reset-password",
    meData: "/auth/me"
  },
  procedures: {
    getAllProcedures: `/admin/procedure/get-procedures`,
    getProcedure: (procedure_id) => `/admin/procedure/get-procedure/${procedure_id}`,
    create: `/admin/procedure/create-procedure`,
    update: (procedure_id) => `/admin/procedure/update-procedure/${procedure_id}`,
    delete: (procedure_id) => `/admin/procedure/delete-procedure/${procedure_id}`,

    sets: {
      getAll: (procedureId) => `/admin/procedure-set/get-procedure-sets/${procedureId}`,
      create: `/admin/procedure-set/create-procedure-set`,
      delete: (procedureSetId) => `/admin/procedure-set/delete-procedure-set/${procedureSetId}`,
      updateStatus: (procedureSetId) => `/admin/procedure-set/change-procedure-set-status/${procedureSetId}`,
      update: (procedureSetId) => `/admin/procedure-set/update-procedure-set/${procedureSetId}`
    },

    steps: {
      getSteps: (procedureId, procedureSetId) =>
          !procedureSetId ? `/admin/procedure-step/get-procedure-steps/${procedureId}`
            : `/admin/procedure-step/get-procedure-steps/${procedureId}/${procedureSetId}`,
      create: `/admin/procedure-step/create-procedure-step`,
      update: (procedure_step_id) => `/admin/procedure-step/update-procedure-step/${procedure_step_id}`,
      delete: (procedure_step_id) => `/admin/procedure-step/delete-procedure-step/${procedure_step_id}`
    }
  },
  surveys: {
    getAll: `/admin/survey/get-surveys`,
    get: (surveyId) => `/admin/survey/get-survey/${surveyId}`,
    create: `/admin/survey/create-survey`,
    update: (surveyId) => `/admin/survey/update-survey/${surveyId}`,
    delete: (surveyId) => `/admin/survey/delete-survey/${surveyId}`,
    updateStatus: (surveyId) => `/admin/survey/change-survey-status/${surveyId}`,
    getAvailableUsers: (surveyId) => `/admin/survey-user/get-available-users`,
    assignToUsers: `/admin/survey-user/assign-survey-users`,
    getSurveyUsers: (surveyId) => `/admin/survey-user/get-survey-users/${surveyId}`,
    unassignUser: (userProcedureId) => `/admin/survey-user/unassign-survey-user/${userProcedureId}`,
    getSurveyShortAnswer:(surveyQuestionId,page)=>`/admin/survey-question/get-short-question-answers/${surveyQuestionId}?page=${page}&size=1`,
    questions: {
      getAll: (surveyId) => `/admin/survey-question/get-survey-questions/${surveyId}`,
      create: `/admin/survey-question/create-survey-question`,
      update: (surveyQuestionId) => `/admin/survey-question/update-survey-question/${surveyQuestionId}`,
      delete: (surveyQuestionId) => `/admin/survey-question/delete-survey-question/${surveyQuestionId}`
    },

    users: {
      getAll: (surveyId) => `/admin/survey-user/get-survey-users/${surveyId}`
    }
  },
  faqTips: {
    getAllFaqTips: (procedureId) => `/admin/procedure-tip/get-procedure-tips/${procedureId}`,
    getFaqTip: (procedureTipId) => `/admin/procedure-tip/get-procedure-tips/${procedureTipId}`,
    create: `/admin/procedure-tip/create-procedure-tip`,
    update: (procedureTipId) => `/admin/procedure-tip/update-procedure-tip/${procedureTipId}`,
    delete: (procedureTipId) => `/admin/procedure-tip/delete-procedure-tip/${procedureTipId}`
  },
  users: {
    getAllUsers: `/admin/user/get-users`,
    getUser: (userId) => `/admin/user/get-user/${userId}`,
    cancelUserProcedure: (userProcedureId) => `/admin/user/delete-user-procedure/${userProcedureId}`,
    deleteUser: (userId) => `/admin/user/delete-user/${userId}`,
    editUser: (userId) => `/admin/user/edit-user/${userId}`,
    editUserProcedure: (userProcedureId) => `/admin/user/update-user-procedure/${userProcedureId}`
  }
};

