
const KVPair = ({
    title, value, titleStyles, valueStyles, styles,color="#ABABAB"
}) => {
    return (
        <div style={{display: 'flex', alignItems: 'center', gap: '15px', ...styles}}>
            <div style={{color: '#3E3E3E', ...titleStyles}}>{title}</div>
            <div style={{color, ...valueStyles}}>{value}</div>
        </div>
    )
}


export default KVPair;
