import Modal from "react-bootstrap/Modal";
import styles from "../surveys.module.scss";
import Spinner from "react-bootstrap/Spinner";
import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import filter_styles from "./filterPopup.module.scss";
import {fetchGetRequest} from "../../../utils/network";
import endpoints from "../../../utils/endpoints";
import toastMessage from "../../../utils/toastMessage";
import constants from "../../../utils/constants";

const procedures = [
    {procedureId: '', title: 'Default'}
]

const FiltersPopup = ({
    onClose, setFilters, filters
}) => {
    const [procedureSets, setProcedureSets] = useState(null);
    const [procedures, setProcedures] = useState(null);

    const [filterData, setFilterData] = useState(filters);

    const [fetchingSets, setFetchingSets] = useState(false);

    const fetchProceduresData = async () => {
        try {
            const resp = await fetchGetRequest(endpoints.procedures.getAllProcedures);
            if(Array.isArray(resp.data.procedures)) {
                setProcedures(resp.data.procedures);
            }
        } catch (err) {
            console.error(err);
            toastMessage(constants.TOAST_TYPE.ERROR, err.data.message);
        }
    }

    const fetchSetsData = async () => {
        if(!filterData.procedureId) return;
        setFetchingSets(true);
        setProcedureSets(null)
        try {
            const resp = await fetchGetRequest(endpoints.procedures.sets.getAll(filterData.procedureId));
            if(Array.isArray(resp.data.procedureSets)) {
                const defaultSet = {
                    setName: 'Default', procedureSetId: 'Default'
                };
                const sets = [defaultSet, ...resp.data.procedureSets];
                setProcedureSets(sets);
            }
        } catch(err) {
            console.error(err);
            toastMessage(constants.TOAST_TYPE.ERROR, err?.data?.message);
        } finally {
            setFetchingSets(false);
        }
    }

    const applyFilters = () => {
        setFilters(filterData);
        onClose?.();
    }

    const clearFilters = () => {
        setFilters({})
        onClose?.();
    }

    useEffect(() => {
        fetchProceduresData();
        setFilterData(filters);
    }, []);

    useEffect(() => {
        if(filterData.procedureId) {
            fetchSetsData();
        }
    }, [filterData.procedureId]);

    return (
        <Modal show={true} scrollable size="lg">
            <Modal.Header style={{
                borderBottom: 'none', display: 'flex', alignItems: 'flex-start',  paddingTop: '40px',
                paddingBottom: '20px', paddingLeft: '40px', paddingRight: '40px'
            }}>
                <Modal.Title style={{textAlign: 'left', width: '100%'}}>Filter By</Modal.Title>
                <img className={styles.clickable} src="/images/close-icon.png"
                     style={{width: '30px', height: '30px'}} onClick={onClose} />
            </Modal.Header>
            <Modal.Body>
                {
                    procedures === null
                    ?   (
                        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '20px'}}>
                            <Spinner animation="border" />
                        </div>
                    )
                    :
                        <Form className={filter_styles.filterForm} style={{padding: '0 25px'}}>
                            <Form.Group className="mb-3">
                                <Form.Label>Procedure</Form.Label>
                                <Form.Select value={filterData?.procedureId || ""}
                                     onChange={e =>
                                         setFilterData(x => ({
                                             ...x, procedureId: e.target.value
                                         }))}>
                                    <option value="" disabled>Select Procedure</option>
                                    {procedures.map(procedure =>
                                        <option value={procedure.procedureId}>{procedure.title}</option>)}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Task List</Form.Label>
                                {fetchingSets
                                    ? <div style={{display: 'flex', justifyContent: 'center', paddingTop: '20px'}}>
                                        <Spinner animation="border"/>
                                    </div>
                                    :
                                    (
                                        <Form.Select value={filterData?.procedureSetId || ""}
                                             onChange={e =>
                                                 setFilterData(x => ({
                                                     ...x, procedureSetId: e.target.value
                                                 }))}>
                                            <option value="" disabled>Select</option>
                                            {procedureSets?.map(procedureSet =>
                                                <option
                                                    value={procedureSet.procedureSetId}>{procedureSet.setName}</option>)}
                                        </Form.Select>)
                                }
                                </Form.Group>
                            </Form>
                        }
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'flex-end', borderTop: 'none'}}>
                <button className={styles.secondaryBtn} style={{
                        padding: '5px 30px', width: '180px', borderColor: '#4DCDCD', color: '#4DCDCD'
                    }} onClick={clearFilters}>
                    Clear Filters
                </button>
                <button className={styles.primaryBtn} style={{
                    padding: '5px 0px', width: '180px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                }} onClick={applyFilters}>
                        Apply
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default FiltersPopup;
