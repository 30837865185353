import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Card from 'react-bootstrap/Card';
import {useState, useContext} from "react";
import users_styles from "../users.module.scss";
import {getFormattedDate} from "../../../utils";


const CustomToggle = ({faqAndTip, eventKey}) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey, () => {});
    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '20px', padding: '7px 9px'}}
             onClick={decoratedOnClick} className={users_styles.hoverable}>
            <h6 style={{color: '#000000', marginBottom:0}}>{faqAndTip.question}</h6>
            <p style={{color: '#AAAAAA', marginBottom:0}}>{faqAndTip.viewedOn ? `Viewed on ${getFormattedDate(new Date(faqAndTip.viewedOn))}` : "Not Viewed"}</p>
        </div>
    )
}


const FaqAndTipsList = ({
    faqAndTips
}) => {

    return (
        <>
            <Accordion defaultActiveKey="">
                {faqAndTips.map((faqAndTip, i) => (
                    <Card style={{
                            backgroundColor: '#F7F7F7', border: 'none', boxShadow: '0px 4px 4px 0px #B2B2B260',
                            marginTop: '10px', marginBottom: '10px'
                        }} key={faqAndTip.tipId}>
                        <Card.Header style={{border: 'none'}}>
                            <CustomToggle faqAndTip={faqAndTip} eventKey={faqAndTip.tipId} />
                        </Card.Header>
                    </Card>
                ))}
            </Accordion>
        </>
    )
}


export default FaqAndTipsList;
